import React from 'react';
import './style.css';

class ResultRecord extends React.Component{
    searchFunc=(word, sentence, startPos)=>{
        let round = 0;
        let position = sentence.indexOf(word, startPos) !==-1? sentence.indexOf(word, startPos) : 0;
        while (((sentence[position-1])!==' ' && sentence[position-1] !== undefined) ||
                (sentence[position+word.length] !==' ' && sentence[position+word.length] !==',' && sentence[position+word.length] !=='.')){
            if(sentence.indexOf(word, position+1) !== -1) position = sentence.indexOf(word, position+1);
            if(round>10) break;
            round++;
        }
        return position;
}

    compareFunc = (word, sentence, word2, sentence2)=>{

        let position1 = this.searchFunc(word, sentence, 0)
        let position2 = this.searchFunc(word2, sentence2, 0);

        if(position2 - position1>10) {
            position1 = this.searchFunc(word, sentence, position1+1)
        }
        else if(position2 - position1 > -10) position2= this.searchFunc(word2,sentence2, position2+word.length2+1);
        if(word === '__') position1 = position2;
        if(word2 === '__') position2 = position1;


        return [position1, position2]

    }
    render() {
        const recordData = {
            textType: this.props.data.text_type,
            speechPart: this.props.data.part_of_speech,
            errorCategory: this.props.data.error_category,
            errorType: this.props.data.error_type,
            name: this.props.data.name,
            age: this.props.data.age,
            sex: this.props.data.sex,
            language: this.props.data.native_language,
            year: this.props.data.year,
            word: this.props.data.word,
            context1: this.props.data.context1,
            context2: this.props.data.context2,
            correct: this.props.data.correct
        };
        let [position1, position2] =
            this.compareFunc(recordData.word.toLowerCase(),
                            recordData.context1.toLowerCase(),
                            recordData.correct.toLowerCase(),
                            recordData.context2.toLowerCase());



        return(

            <div className={"result-record-container"}>
                <h3 className={"record-heading"}>{`${this.props.index}. ${recordData.textType }, ${recordData.speechPart}, 
                ${recordData.errorCategory} (${recordData.errorType}), ${recordData.name} (
                ${recordData.age}, ${recordData.language})|${recordData.year}`}</h3>

                <h3 className={"context1"}>{recordData.context1.substr(0, position1)}
                <span>{recordData.word}</span>
                    {
                       recordData.word !== '__'? recordData.context1.substr(position1+(recordData.word.length)):
                           '_ '+ recordData.context1.substr(position1)
                    }
                </h3>
                <h3 className={"correct"}>{recordData.context2.substr(0, position2)}
                <span>
                    {recordData.correct}
                </span>
                    {
                        recordData.correct !=='__'? recordData.context2.substr(position2+recordData.correct.length):
                            '_ '+recordData.context2.substr(position2)
                    }
                </h3>
            </div>
        )
    }
}

export  {ResultRecord};