import React, {Fragment} from 'react';
import './style.css';

const textArray=[
    {
        id:'1',
        contentHeadline:'Что такое Электронная база речевых ошибок детей-инофонов?',
        contentText:<div>База содержит речевые ошибки детей-инофонов, обучающихся в школах
            города Череповца Вологодской области. Дети-инофоны – это дети мигрантов,
            переехавших в Россию относительно недавно, владеющие русским языком на низком,
            пороговом уровне. Инофоны относятся к нестандартным носителям русского языка.
            Материалом послужили учебные письменные тексты: диктанты, сочинения, изложения, списывание.
            При проектировании базы учитывался опыт разработчиков учебных
            корпусов нестандартных носителей русского языка разных категорий. <br/>
            Прежде всего это <a href={'http://www.web-corpora.net/RLC'}>Русский учебный корпус</a>,
            разрабатываемый сотрудниками Лаборатории по корпусным исследованиям НИУ ВШЭ под руководством Екатерины Рахилиной.<div id={'i2'}/></div>
    },
    {
        id:'2',
        contentHeadline:'Метаданные',
        contentText:<div>В базе учтены следующие метаданные:
            <ul>
                <li>имя ребенка-инофона</li>
                <li>возраст</li>
                <li>пол</li>
                <li>родной язык</li>
                <li>тип текста, в котором встретилась ошибка</li>
                <li>год создания текста</li>
            </ul>
            <div id={'i3'}/>
            </div>
    },
    {
        id:'3',
        contentHeadline:'Языки',
        contentText: <div>На данный момент в базе представлены тексты, созданные детьми-инофонами, для которых родными языками являются: <br/>
        <ul>
            <li>армянский </li>
            <li>азербайджанский</li>
            <li>узбекский </li>
            <li>киргизский</li>
            <li>таджикский</li>
            <li>даргинский</li>
            <li>аварский</li>
            <li>талышский</li>
            <li>белорусский</li>
            <li>украинский</li>
            <li>вьетнамский</li>
        </ul>
            <div id={'i4'}/>
        </div>
    },
    {
        id:'4',
        contentHeadline:'Типология ошибок',
        contentText:<div>
            Неорфографические ошибки
            <ul>
                <li>написания слитно, раздельно, через дефис,</li>
                <li>орфоэпические ошибки (пропуски, добавления, перестановки),</li>
                <li>ошибки, связанные с недостаточным осмыслением текстовой ситуации (к этому виду были отнесены и все случаи слитного написания предлогов);</li>
            </ul>
            Графические ошибки:
            <ul>
                <li>искажения в начертании букв (замена смежных букв, зеркальные буквы и пр.),</li>
                <li>написание прописных и строчных букв,</li>
                <li> способ отражения на письме звука [ш’],</li>
                <li>двойные/одинарные согласные в аспекте отражения долготы;</li>
                <li>глухие и звонкие согласные в сильной поз̵иции;</li>
                <li>гласные в сильной позиции;</li>
                <li>пропуск Ь и Ъ в их разделительной функции;</li>
                <li>пропуск Ь при обозначении мягкости;</li>
                <li>пропуск Ь для обозначения грамматической формы слова;</li>
                <li>замена или пропуск буквы Й;</li>
                <li>замена гласных при обозначении твёрдости-мягкости согласных фонем, замены Э-Е, А-Я, И-Ы, О-Ё и обратные замены;</li>
                <li>ненормативные написания после шипящих и Ц;</li>
                <li>прочие особенности отражения твёрдости-мягкости на письме (обозначение ассимилятивной мягкости, избыточное маркирование мягкости и пр.);</li>
            </ul>
            Орфографические ошибки:
            <ul>
                <li>разнообразные ненормативные написания 1-го типа с использованием принципа «пишу, как слышу»;</li>
                <li>разнообразные ненормативные написания 2-го типа с использованием принципа «пишу не так, как слышу»;</li>
            </ul>
            Лексические ошибки:
            <ul>
                <li>неверное словоупотребление;</li>
                <li>неснятая многозначность, порождающая двоякое осмысление высказывания;</li>
                <li>неполнота высказывания;</li>
                <li>лексические повторы в составе одного предложения или между несколькими предложениями;</li>
            </ul>
                Грамматические ошибки:
            <ul>
                <li>неверная форма числа;</li>
                <li>ошибки в согласовании;</li>
                <li> ошибки в управлении;</li>
                <li>ошибки в координации;</li>
                <li> изменение родовой принадлежности слова;</li>
                <li>нарушение глагольной формы (включая причастия и деепричастия);</li>
                <li>ошибка в употреблении деепричастия;</li>
                <li>прочие грамматические ошибки (ошибки в употреблении союза, в употреблении предлога, неверная падежная форма имени существительного).<div id={'i5'}/></li>
            </ul>
        </div>
    },
    {
        id:'5',
        contentHeadline:'Использование базы данных',
        contentText:<div>
            Электронная база речевых ошибок детей-инофонов обладает большим
            информационным потенциалом для решения многих исследовательских
            и образовательных задач. С её помощью можно изучать специфику речевых
            ошибок детей-инофонов в зависимости от возрастной, этнической группы,
            а также от типа текста; разрабатывать наиболее эффективные методики
            раннего диагностирования языковых проблем детей-инофонов и методические
            рекомендации в части организации индивидуальных траекторий в обучении
            русскому языку детей указанной категории.
        </div>
    }


];


const MainPage = ()=>{
    return(
        <div className="main-page-container">
            <Menu>
                <h1 className={'navbar-heading'}>База данных "Дети инофоны Вологодской области"</h1>
                {textArray.map(item=><MenuLink key={item.id} data = {item}/>)}
            </Menu>
            <Content>
                {textArray.map(item=><Block key={item.id} data={item}/>)}
            </Content>
        </div>
    )
};

const Menu = (props) =>{
    return(
        <nav className="main-page-menu">
            <ul>
            {props.children}
            </ul>
        </nav>
    )
};

const Content=(props)=>{
    return(
        <div className="content-container">
            <div className={'main-page-heading'}><h1>База данных "Дети-инофоны Вологодской области"</h1></div>
            <div className="content-container-text">
                <div className={'content-heading-text'}>
                    Работа выполнена при поддержке Российского фонда фундаментальных
                    исследований (проект №18-412-350003 «Создание электронной базы
                    речевых ошибок детей-инофонов, обучающихся в общеобразовательных
                    школах города г. Череповца Вологодской области»).<div id={'i1'}/>
                </div>
            {props.children}
            </div>
        </div>
    )
};



const Block=(props)=>{
    const data=props.data;
    return(
        <Fragment>
            <h3 className="content-headline">{data.contentHeadline}
            </h3>
            {data.contentText}
        </Fragment>
    )
};

const MenuLink=(props)=>{
 const data = props.data;
 return(
     <Fragment><li><a href={'#i'+data.id} className={'menu-link'}>{data.contentHeadline}</a> </li></Fragment>
 )
};
export {MainPage};