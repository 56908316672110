import { combineReducers } from 'redux'
import {userReducer} from "./UserReducer";
import {searchReducer} from "./SearchReducer";
import {statisticsReducer} from "./StaticsReducer";
import {adminReducer} from "./AdminReducer";

export const rootReducer = combineReducers({
  userReducer,
  searchReducer,
  statisticsReducer,
  adminReducer
});