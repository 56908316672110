export const connectionData = {
    url: 'https://server.inophone35.ru/',
    options: {
        mode: 'cors',
        headers:{
            'Content-Type': 'application/json'
        }
    }
};

