import React, {Fragment} from 'react';
import {
    YMaps,
    Map,
    Placemark
} from 'react-yandex-maps';
import './style.css';
import grudeva from '../../../img/Grudeva.jpg';
import diveeva from '../../../img/Diveeva.jpg';
import buchilova from '../../../img/Buchilova.jpg';
import ivanova from '../../../img/EIIvanova.png'
import logo from '../../../img/chsuicon.png';
const mapData = {
    center: [59.12039280884961,37.9308576534423],
    zoom: 16
};


const teammatesData=[
    {
        id: 1,
        src2: grudeva,
        alt: "Грудева Елена Валерьевна",
        name: "Грудева Елена Валерьевна",
        position: "Доктор филологических наук, профессор",
        education: 'В 1992 году закончила Череповецкий государственный педагогический\n' +
            'институт (ЧГПИ) им. А.В. Луначарского, филологический факультет\n' +
            '(специальность: русский язык и литература).\n' +
            'С 1993 по 1996 г. обучалась в очной аспирантуре на кафедре русского\n' +
            'языка Российского государственного педагогического университета им.\n' +
            'А.И. Герцена в Санкт-Петербурге.\n' +
            'В декабре 1996 г. защитила кандидатскую диссертацию на тему\n' +
            '«Хронологически отмеченная лексика в современном русском языке и ее\n' +
            'лексикографическая интерпретация» по специальности 10.02.01 – русский\n' +
            'язык (научный руководитель – доктор педагогических наук, кандидат\n' +
            'филологических наук, профессор В.А. Козырев).\n' +
            'С 2000 по 2003 г. обучалась в докторантуре на кафедре общего\n' +
            'языкознания Санкт-Петербургского государственного университета.\n' +
            'В 2009 году защитила докторскую диссертацию на тему:\n' +
            '«Избыточность текста, редукция и эллипсис (на материале русского языка)»\n' +
            'по специальности 10.02.19 – теория языка (научный консультант – доктор\n' +
            'филологических наук, профессор В.Б. Касевич).\n' +
            'С мая 2011 г. по настоящее время заведует кафедрой отечественной\n' +
            'филологии и прикладных коммуникаций Череповецкого государственного\n' +
            'университета.\n' +
            'Является одним из разработчиков Корпуса русского литературного\n' +
            'языка (www.narusco.ru), руководит научной школой «Корпусная\n' +
            'лингвистика».',
        hobbies: 'корпусная лингвистика; психолингвистика, восприятие\n' +
            'речи, речевая деятельность детей-инофонов, русский язык как иностранный,\n' +
            'морфология и синтаксис русского языка.'
    },
    {
        id: 2,
        src2: ivanova,
        alt: "Иванова Елена Михайловна",
        name: "Иванова Елена Михайловна",
        position: "Кандидат филологических наук, доцент кафедры социальных коммуникаций и медиа, заместитель заведующего кафедрой по научной работе, научный руководитель МБОУ \"СОШ № 21 с углублённым изучением отдельных предметов\", лингвист-эксперт, тестолог в системе ТРКИ, координатор \"Тотального диктанта\" в Череповце, член жюри телевизионной гуманитарной олимпиады «Умники и умницы Вологодчины», педагог Международной школы русского языка и русской культуры \"В.Даль\".",
        education: '',
        hobbies: 'корпусная лингвистика; психолингвистика, восприятие\n' +
            'речи, речевая деятельность детей-инофонов, русский язык как иностранный,\n' +
            'морфология и синтаксис русского языка.',
    },
    {
        id: 3,
        src2: buchilova,
        alt: "Бучилова Ирина Анатольевна",
        name: "Бучилова Ирина Анатольевна",
        position: "Кандидат психологических наук, доцент",
        education: 'В 1994 году закончила Череповецкий государственный педагогический\n' +
            'институт (ЧГПИ). В 2002 году защитила кандидатскую диссертацию по\n' +
            'педагогической психологии в г. Курске на тему \'Психологические\n' +
            'особенности преодоления педагогических стереотипов у воспитателей и\n' +
            'студентов педвузов\' (научный руководитель: доктор психологических наук,\n' +
            'проф. В.Г.Маралов).\n' +
            'В 2019 г. закончила аспирантуру в ЧГУ (направление подготовки: 10.02.01 -\n' +
            'Русский язык: научный руководитель: д.филол.наук, проф. Е.В. Грудева).\n' +
            'С 1998 года основное место работы - Череповецкий государственный\n' +
            'университет (кафедра дефектологического образования).',
        hobbies: 'психолого-педагогическая диагностика детей с\n' +
            'нарушениями в развитии; технологии работы с родителями,\n' +
            'воспитывающими детей с ОВЗ; арттерапия в коррекционной педагогике,\n' +
            'технологии коррекции речевых нарушений, социокультурная и языковая\n' +
            'адаптация детей-мигрантов, изучение ошибок в письменных текстах детей-\n' +
            'инофонов.'
    },
    {
        id: 4,
        src2: diveeva,
        alt: "Алина Альбертовна Дивеева",
        name: "Алина Альбертовна Дивеева",
        position: "Аспирант кафедры отечественной филологии и прикладных коммуникаций",
        education: 'Высшее образование\n' +
            'Череповецкий государственный университет; филология, русский язык в функционально-\n' +
            'коммуникативном аспекте; магистр филологии',
        hobbies: 'психолингвистика, ассоциативный эксперимент, текстопорождение, русский язык как\n' +
            'иностранный, речевая деятельность детей-инофонов, современная массовая культура, рэп-текст,\n' +
            'лингвистика текста',
    }
];







const AboutPage = ()=>{
    return(
        <div className="about-page-container">
            <OrganisationBlocK/>
            <Team/>
            <Articles/>

        </div>
    )
};

const OrganisationBlocK = props=>{
    return(
        <div className={'about-block'}>
            <div className={'about-organisation-container'}>
            <img src={logo} alt={'chsu-logo'}/>
            <div className={'about-organisation-text-container'}>
                <h3 className={'about-organisation-heading-text'}>Кафедра отечественной филологии и прикладных коммуникаций Череповецкого государственного университета</h3>
                <h3 className={'about-organisation-subheading-text'}>О кафедре</h3>
                <h3 className={'about-organisation-text'}>Кафедра отечественной филологии и прикладных коммуникаций образована 1 июля 2011
                года в результате объединения двух кафедр – кафедры русского языка и общего языкознания
                и кафедры литературы, основанных в 1962 году. Являясь одной из выпускающих кафедр
                Гуманитарного института ЧГУ, кафедра отечественной филологии и прикладных коммуникаций
                с 2001 года ведет подготовку по специальности 031001.65 – «Филология» (русский язык и литература).
                Сферами профессиональной деятельности выпускников являются школы и другие детские учреждения; отделы
                по связям с общественностью; отделы сбыта и отделы кадров крупных предприятий; электронные
                    и печатные средства массовой информации; учреждения культуры; книжный бизнес.</h3>
                    <h3 className={'about-organisation-subheading-text'}>Научная деятельность</h3>
                <h3  className={'about-organisation-text'}>За время существования кафедры сформировалось четыре научные школы: «Лингвокогнитивный анализ языковых
                единиц» (научный руководитель – д.ф.н., проф. С.Ю. Лаврова), «Текст как многоаспектное лингвистическое
                явление» (научный руководитель – к.ф.н., проф. Р.Л. Смулаковская), «Диалектная фонетика, лексикография
                и фонетика Белозерья» (д.ф.н., профессор Н.А. Волкова, к.ф.н., доцент Н.П. Тихомирова); «Корпусная лингвистика» (д.ф.н., профессор Е.В. Грудева).
                Кафедра выступает организатором секции «Дебют» в рамках ежегодной студенческой научной конференции,
                участниками которой являются не только студенты младших курсов, но и учащиеся старших классов школ Череповца
                и Череповецкого района. С 2001 года ежегодно проводит совместно с Петербургским лингвистическим обществом
                выездную школу-семинар «Порождение и восприятие речи».
                Ежегодно преподаватели и аспиранты кафедры активно участвуют в международных конференциях: Москва,
                Санкт-Петербург, Вологда, Новгород, Ярославль, Оренбург, Тверь, Коломна, Вильнюс (Литва), Гродно (Беларусь),
                    Витебск (Беларусь), Познань (Польша), Варшава (Польша), Берлин (Германия), Париж (Франция).</h3>
            </div>
            </div>
            <div className={'geolocation-container'}>
                <div style={{"width": "361px"}}>
                    <h3>Адрес:<br/>
                    ФГБОУ ВО «Череповецкий<br/>
                    государственный университет»<br/>
                    Советский пр-т, д. 8,<br/>
                    г. Череповец, Вологодская обл., Россия<br/>
                    Тел./ факс: (8202) 55-65-97<br/>
                    E-Mail: chsu@chsu.ru</h3>
                </div>
                <div>
                    <YMaps>
                        <Map width={361} height={300} defaultState={mapData}>
                            <Placemark geometry={[59.12039280884961,37.9308576534423]}/>
                        </Map>
                    </YMaps>
                </div>
            </div>
        </div>
    )
};


const Teammate = ({data}) =>{
    return(
        <div className={'about-block teammate-block'} id={data.id}>
        <img src={data.src2} alt={data.alt} className={'about-teammate-img'}/>
        <div className={'teammate-text-container'}>
            <h3 className={'teammate-name'}>{data.name}</h3>
            <h3 className={'teammate-position'}>{data.position}</h3>
            <h3 className={'teammate-education'}>{data.education}</h3>
            <h3 className={'teammate-hobbies'}><span style={{"fontWeight": "bold"}}>Научные интересы:</span> {data.hobbies}</h3>

        </div>
        </div>
    )
};

const Team = ()=>{
    return(
        <Fragment>
            {
                teammatesData.map((item, index)=><Teammate key={index} data={item}/>)
            }
        </Fragment>
    )
};


const Articles = () =>{
    const articles = [
        {
            authors: 'Бучилова И. А., Савич Е.В., Белянкина В.А., Филичева Т.М.',
            name: 'Изучение особенностей языковой личности ребенка-инофона младшего школьного возраста в аспекте этнической и языковой идентичности // Материалы IV Международной научной конференции «Актуальные проблемы педагогики и психологии начальной школы» (18 - 19 апреля 2019). Прага, 2019. С. 5 – 7.'
        },
        {
            authors: 'Бучилова И.А.',
            name: 'Диагностика сформированности гражданской идентичности учащихся в условиях поликультурного образовательного пространства // Мир образования – образование миру. № 1 (73). – М., 2019. – С. 29 – 35.'
        },
        {
            authors: 'Бучилова И.А., Гаврилова И.А., Гущина И.Н.',
            name: 'К вопросу об особенностях письменных текстов детей-инофонов младшего школьного возраста // Повышение качества образования в современных условиях. Сб-к трудов IV Всероссийской научно-практической конференции (15 февраля 2019, Якутск). – Киров, Изд-во МЦИТО, 2019. С. 141 – 143.'
        },
        {
            authors: 'Бучилова И.А., Грудева Е.В., Дивеева А.А., Иванова Е.М.',
            name: 'Создание электронной базы ошибок в письменных текстах детей-инофонов: проблемы и решения // Материалы ежегодной международной научной конференции 24 – 26 июня 2019 СПб. «Современная онтолингвистика проблемы, методы, открытия» – Иваново: ЛИСТОС. 2019. - 640 с.'
        },
        {
            authors: 'Бучилова И.А., Грушина С.А., Казакова Е.О.',
            name: 'Особенности социального конструирования гражданской идентичности учащихся начальных классов в системе поликультурного образования // Сборник научных трудов Всероссийской научно-практической конференции  «Педагогическое образование: вызовы 21 века», посвященной памяти академика В.А. Сластенина (Новосибирск, 20 – 27 сентября 2019). // Под ред. Е.В. Андриенко, Л.П. Жуйковой - Новосибирск, Изд-во НГПУ, 2019. – С. 180 – 183.'
        },
        {
            authors: 'Бучилова И.А., Инокентьева  О.Ю., Шпиль С. Б.,Махова И.А.',
            name: 'Изучение лексического запаса детей-инофонов, обучающихся в начальной школе // Материалы   IV Международной научной конференции  «Развитие личности: психологические основы и социальные условия» (29 – 30 марта 2019). Прага, 2019. С. 61 – 63.'
        },
        {
            authors: 'Бучилова И.А., Поспелова С.Н., Махова И.А.',
            name: 'Накопление эмпирического материала для создания электронной базы ошибок в письменных текстах детей-инофонов // Материалы  IX  Международной научной конференции «Общество, культура, личность». Прага, 2019. С. 48 – 49'
        },
        {
            authors: 'Грудева Е.В.',
            name: 'Русский язык в типологической перспективе: аналитизм или изоляция? // Слово. Словарь. Словесность: к столетию кафедры русского языка и 95-летию профессора Сакмары Георгиевны Ильенко: сборник научных статей / под ред. проф. В.Д. Черняк. — СПб.: Изд-во РГПУ им. А. И. Герцена, 2019. – С. 155-159.'
        },
        {
            authors: 'Грудева Е.В., Бучилова И.А.',
            name: 'Влияние родного языка детей-инофонов на возникновение ошибок в текстах на русском языке // Вестник Череповецкого государственного университета. - 2019. - № 6 (92). - С. 63-74.'
        },
        {
            authors: 'Грудева Е.В., Бучилова И.А., Дивеева А.А., Иванова Е.М.',
            name: 'Речевая компетентность ребёнка-инофона (на материале корпуса ошибок детей-инофонов г. Череповца) // Слово. Словарь. Словесность: к столетию кафедры русского языка и 95-летию профессора Сакмары Георгиевны Ильенко: сборник научных статей / под ред. проф. В.Д. Черняк. — СПб.: Изд-во РГПУ им. А. И. Герцена, 2019. – С. 274-278.'
        },
        {
            authors: 'Грудева Е.В., Селяничев О.Л., Трапезников М.С.',
            name: 'Организация деятельности студентов для реализации исследовательского проекта // Информационные и педагогические технологии в современном образовательном учреждении: Мате-риалы X Всероссийской научно-практической конференции (Череповец, 5 апреля 2019 г.). - Череповец: ЧГУ, 2019. - С. 187-190.'
        },
        {
            authors: 'Иванова Е.М., Дивеева А.А.',
            name: 'Ошибки детей-инофонов в разных типах текстов (на материале электронной базы речевых ошибок детей-инофонов, обучающихся в общеобразовательных школах города Череповца Вологодской области) // Вестник Череповецкого государственного университета. - 2019. № 6 (92). - С. 86-98. '
        },
        {
            authors: 'Grudeva E.V., Buchilova I.A., Diveeva A.A., Ivanova E.M., Selyanichev O.L., Trapeznikov M.S.',
            name: 'ELECTRONIC DATABASE OF NON-NATIVE SPEAKERS’ SPEECH ERRORS AS A SCIENTIFIC AND EDUCATIONAL RESOURCE (ON THE EXAMPLE OF RUSSIAN SCHOOLS)'
        },
        {
            authors: 'Грудева Е.В., Бучилова И.А.',
            name: 'Типология ошибок в письменных текстах детей-инофонов среднего школьного возраста // Материалы Международной ежегодной конференции «Проблемы онтолингвистики – 2018» / Отв. ред. Т.А. Круглякова. -  С.-Петербург. - С. 213-217.'
        },
        {
            authors: 'Бучилова И.А.',
            name: 'Специфические ошибки в письменных работах учащихся-инофонов // Вестник Череповецкого государственного университета. № 4 (85). 2018. С. 35 – 47'
        },
        {
            authors: 'Бучилова И.А.',
            name: 'Особенности языковой, этнической и гражданской идентичности двуязычных детей, обучающихся в школах г. Череповца // Вестник Череповецкого государственного университета. № 5 (86). 2018. С. 144 – 151'
        },
        {
            authors: 'Грудева Е.В., Бучилова И.А., Волкова Н.А.',
            name: 'Корпусы ошибок: целевая аудитория, возможная архитектура корпуса // Вестник Череповецкого государственного университета. № 5 (86). 2018. С. 63 – 72'
        },
        {
            authors: 'Бучилова И.А., Попова С.И., Соловьева Е.Е.',
            name: 'Типология ошибок в аспекте речевой деятельности детей-инофонов // Вестник Череповецкого государственного университета. № 6.  2018. С. 78 – 89.'
        },
        {
            authors: 'Бучилова И.А., Попова С.И., Соловьева Е.Е.',
            name: 'К проблеме формирования гражданской идентичности учащихся в условиях поликультурного образования Вологодской области. // Социальная несправедливость в социологическом измерении: вызовы современного мира: XII Международная научная конференция «Сорокинские чтения – 2018»: Сборник материалов. – М.: МАКС Пресс, 2018. – С. 436-437.'
        },
        {
            authors: 'Грудева Е.В., Бучилова И.А., Дивеева А.А., Иванова Е.М.',
            name: 'К вопросу об этнической и лингвистической идентичности языковой личности двуязычного школьника // Язык как отражение духовной культуры народа: материалы Международной науч-ной конференции 18–20 октября 2018 г., Архангельск / Сев. (Аркт.) федер. ун-т им. М. В. Ломоносова ; [сост. В. А. Марьян-чик]. – Архангельск: КИРА, 2018. – С. 217-220.'
        },
        {
            authors: 'Грудева Е.В., Гунько Л.А.',
            name: 'К построению ассоциативно-семантического поля «Беженцы и мигранты» (на материале ассоциативных словарей русского языка) // Череповецкие научные чтения – 2017. Материалы Всероссийской научно-практической конференции: в 4-х частях. Отв. ред. Е.В. Целикова. – Череповец: ЧГУ, 2018. - С. 32-34.'
        },
        {
            authors: 'О.Л. Леханова, Т.В. Захарова, И.А. Бучилова, Е.В. Грудева',
            name: 'Лингвистические и психолингвистические основы образовательной деятельности: адаптированные учебно-методические материалы: Учебное пособие / Отв. ред. О.А. Денисова. Разработчики: О.Л. Леханова, Т.В. Захарова, И.А. Бучилова, Е.В. Грудева. – Череповец: Чер. гос. ун-т, 2018. – 208 с.'
        },
        {
            authors: 'Бучилова И.А.',
            name: 'Языковая и этническая идентичность детей-билингвов, обучающихся в школах г. Череповца // Проблемы порождения и восприятия речи: Материалы XIV выездной школы-семинара (г. Череповец, 1–3 декабря 2016 г.) / Отв. ред. Е.В. Грудева. - Череповец: Череп. гос. ун-т, 2017. - С. 65-73.'
        },
        {
            authors: 'Грудева Е.В.',
            name: 'Корпусная лингвистика: Учебное пособие. 3-е изд. - М.: Флинта, 2017. – 164 с.'
        },
        {
            authors: 'Грудева Е.В., Бучилова И.А.',
            name: 'Теоретический анализ проблемы обучения русскому языку детей из семей мигрантов // Педагогика и психология, наука и образование: теоретико-методологические подходы и практические результаты исследований: Монография / Под ред. В.А. Куриной, О.А. Подкопаева – Самара: ООО «Офорт», ООО «Поволжская научная корпорация». - 2017 – С. 29 – 41.'
        },
        {
            authors: 'Бучилова И.А., Алиева Т.И.к.',
            name: 'Особенности социокультурной адаптации детей-мигрантов в условиях российских общеобразовательных школ на примере г. Череповца Вологодской области // Миграция в современном мире: тенденции и вызовы XXI века: монография. - Новосибирск: СибАК, 2016. – С. 49 – 73.'
        },
        {
            authors: 'Герасимова М.Н., Грудева Е.В. ',
            name: 'Концепты СВОЙ, ЧУЖОЙ, ДРУГОЙ как зеркало этнической идентичности в русскоязычной картине мира // Язык, текст, коммуникация: прагмалингвистические исследования: монография; [под ред. Е.В. Грудевой]. Новосибирск: Изд. АНС «СибАК», 2016. – С. 8-24.'
        },
        {
            authors: 'Грудева Е.В.',
            name: 'Язык в культуре и культура в языке: монография; [под ред. Е.В. Грудевой]. Новосибирск: Изд. АНС «СибАК», 2016. – 266 с.'
        },
        {
            authors: 'Алиева Т.И.,  Бучилова И.А.',
            name: 'Особенности социокультурной адаптации детей-мигрантов. LAP LAMBERT Academic Publishing, 2015. - 2015 978-3-659-68145-5.'
        },
        {
            authors: 'Бучилова И.А.',
            name: 'К проблеме изучения особенностей письма детей-мигрантов младшего школьного возраста // Universum: филология и искусствоведение, 2015. - № 8 (21) URL: http://7universum.com/ru/philology/archive/item/2563.'
        },
        {
            authors: 'Бучилова И.А.',
            name: 'Специфика письменной коммуникации детей мигрантов в учебных ситуациях и условия ее формирования // Материалы XIII выездной школы-семинара «Порождение и восприятие речи» (г. Череповец, 4 – 6 декабря 2014) / Отв.ред. Е.В. Грудева. – Череповец: ЧГУ, 2015. – С. 45 – 49.'
        },
        {
            authors: 'Бучилова И.А., Алиева Т.И.к.',
            name: 'Особенности социокультурной адаптации детей младшего школьного возраста, воспитывающихся в условиях двуязычия в семьях мигрантов // Научно-методический электронный журнал "Концепт". 2015 http://e-koncept.ru/best_article/?year=2015'
        },
        {
            authors: 'Грудева Е.В.',
            name: 'Взгляды К.Д. Ушинского на грамотность как средство распространения образования // Слово. Словарь. Словесность: Русский язык в научном, культурном и образовательном пространстве (к 190-летию со дня рождения К.Д. Ушинского)»: материалы Всероссийской научной конференции (Санкт-Петербург, 12-13 ноября 2014 г.). – СПб.: Сага, 2015. - С. 30-45.'
        },
        {
            authors: 'Грудева Е.В.',
            name: 'Как корпус изменил наши представления о языке // Проблемы порождения и восприятия речи: Материалы XIII выездной школы-семинара (г. Череповец, 4 – 6 декабря 2014 г.) / Отв. ред. Е.В. Грудева. – Череповец: ЧГУ, 2015. – С. 108-115 (0,39 п.л.).'
        },
        {
            authors: 'Грудева Е.В.',
            name: 'Методика работы с аутентичным текстом в рамках практического курса русского языка как иностранного (на примере рассказа Ярослава Шипова «Медаль») // European Social Science Journal (Европейский журнал социальных наук). - 2015. - № 6. - Том 3. - С. 211-216. '
        },
        {
            authors: 'Грудева Е.В., Бучилова И.А.',
            name: 'К вопросу формирования навыков грамотного письма как средства коммуникации у детей-мигрантов младшего школьного возраста на уроках русского языка // Научно-методический электронный журнал "Концепт". – 2015. - Т. 13. – С. 471-475 http://e-koncept.ru/best_article/?year=2015'
        },
        {
            authors: 'Грудева Е.В., Штыкова Н.В.',
            name: 'Методика преподавания РКИ вьетнамцам в «Школе мигранта» города Череповца. - LAP Lambert Academic Publishing, 2015. – 74 с. ISBN 978-3-659-78685-3.'
        },
        {
            authors: 'Грудева Е.В.',
            name: 'Дискурс и коммуникация: монография; [под ред. Е.В. Грудевой]. - Новосибирск: СибАК, 2015. '
        },
        {
            authors: 'Грудева Е.В.',
            name: '«В начале было Слово…»: Язык, культура и религия в обеспечении национальной безопасности: монография / Под ред. Е.В. Грудевой. – Новосибирск: Изд-во «СибАК», 2014. – 176 с.'
        },
        {
            authors: 'Бучилова И. А.',
            name: 'Методические рекомендации по использованию игровых технологий при обучении русскому языку детей младшего школьного возраста // Современные научные исследования: методология, теория, практика: материалы V международной научно-практической конференции (Самара, 26 декабря 2014 г.). – Самара: ООО "ЦСРНИ", 2014. - C. 107-108.'
        },
        {
            authors: 'Бучилова И. А., Алиева Т. И.',
            name: 'Диагностические аспекты языковой и социокультурной адаптации детей-мигрантов младшего школьного возраста // Социально-психологическая адаптация мигрантов в современном мире: материалы 2-й Международной научно-практической конференции / Ответственный редактор В.В. Константинов. – М.: Издательство «Перо», 2014. – 226 с. (конференция 27 -28 февраля 2014)'
        },
        {
            authors: 'Бучилова И.А.',
            name: 'Анализ орфографических ошибок в диктантах детей-мигрантов младшего школьного возраста / Тhe European Scientific and Practical Соngress «Scientific resources management of countries and regions». 18 july 2014 in Copenhagen (Denmark). – p. 50 – 52.'
        },
        {
            authors: 'Бучилова И.А., Алиева Т.И.',
            name: 'Обоснование основных направлений и этапов работы по обеспечению языковой и социокультурной адаптации детей-мигрантов младшего школьного возраста // Череповецкие научные чтения – 2013. Материалы Всероссийской научно-практической конференции. Череповец, 2014, с. 27 – 28'
        },
        {
            authors: 'Бучилова И.А., Алиева Т.И.',
            name: 'Работа над орфографической грамотностью и расширением словаря у детей-мигрантов младшего школьного возраста на уроках русского языка в начальной школе // Вестник Череповецкого государственного университета. 2014. № 4 (57). - С. 124-127.'
        },
        {
            authors: 'Бучилова И.А., Алиева Т.И.',
            name: 'Социокультурная адаптация детей-мигрантов как фактор обеспечения безопасности среды образовательных учреждений // Личностно-ориентированные технологии в образовании как фактор безопасного развития личности: Монография / Под ред. В.Г. Маралова, Н.В. Гольцовой. – Череповец. 2014. - С. 275 – 286.'
        },
        {
            authors: 'Бучилова И.А., Алиева Т.И.К.',
            name: 'Диагностические аспекты языковой и социокультурной адаптации детей-мигрантов младшего школьного возраста // Социально-психологическая адаптация мигрантов в современном мире Материалы 2-й Международной научно-практической конференции. Ответственный редактор В.В. Константинов. - Москва, 2014. - С. 20-24.'
        },
        {
            authors: 'Герасимова М.Н., Грудева Е.В.',
            name: 'Использование лингвистически аннотированного корпуса текстов на занятиях по дисциплинам «Русский язык» и «Русский язык и культура речи»: из опыта работы // Вестник Череповецкого государственного университета. 2014. №2(55). - С. 65-68.'
        },
        {
            authors: 'Герасимова М.Н., Грудева Е.В.',
            name: 'Языковая личность в этнически ориентированном Рунете (на примере функционирования концептов СВОЙ, ЧУЖОЙ, ДРУГОЙ) // Современная языковая личность: типы порождаемых текстов и методы их изучения: монография; [под ред. Е.В. Грудевой]. - Новосибирск: СибАК, 2014. – С. 105-126.'
        },
        {
            authors: 'Алиева Т.И., Бучилова И.А.',
            name: 'Проблемы социокультурной адаптации детей младшего школьного возраста, воспитывающихся в условиях двуязычия в семьях мигрантов // Социосфера. - 2013. - № 6. - С. 154-157.'
        },
        {
            authors: 'Бучилова И.А., Алиева Т.И.',
            name: 'К вопросу организации консультационного центра для детей из семей мигрантов в Вологодской области // Современные научные исследования. Выпуск 1. – Концепт. – 2013. - ART  53541. – URL:  http://e- concept. ru/article/931/ - Гос. рег. Эл N ФС 77-49965.- ISSN: 2304-120X'
        },
        {
            authors: 'Бучилова И.А., Алиева Т.И.',
            name: 'The problem of language adaptation of school-age children of migrant families // 29th World Congress of the IALP in Torino, Italy August 25 – 29, 2013, p. 117.'
        },
        {
            authors: 'Грудева Е.В., Иванова Е.М., Бучилова И.А., Алиева Т.И.',
            name: 'Консультационный центр для детей мигрантов // Публикация в сетевом издании «Педагогический сайт» [Электронный ресурс]. – Режим доступа. – URL: http://pedsite.ru/publications/86/1486/. Свидетельство № 1510 (05 июля 2014)'
        },
        {
            authors: 'Грудева Е.В., Иванова Е.М., Бучилова И.А., Алиева Т.И.',
            name: 'Обучение русскому языку детей-мигрантов младшего школьного возраста в условиях консультационного центра: постановка проблемы // В мире науки и искусства: вопросы филологии, искусствоведения и культурологии. - 2013. - № 28. - С. 12-19.'
        },
        {
            authors: 'Грудева Е.В.',
            name: 'Из опыта преподавания русского языка как иностранного трудящимся мигрантам (на примере вьетнамской диаспоры в г. Череповце Вологодской области) // Lingua mobilis – 2013. - №6 (45). - С. 74-82.'
        }

    ]

    return <div className={'about-block'}>
        <h3 className={'about-heading'}>Научные публикации</h3>
    {articles.map((item, index)=><h3 key={index} className={'about-articles'}><span >{item.authors}</span>{item.name}</h3>)}
</div>;
}

export{AboutPage};