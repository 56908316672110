import {
    REQUEST_TO_DB,
    DATA_RECEIVED,
    ADD_ERROR_TYPES,
    REQUEST_SUCCESS,
    CREATE_REQUEST,
    CLOSE_TEXT_TYPE_FILTER,
    OPEN_TEXT_TYPE_FILTER,
    CLOSE_PART_OF_SPEECH_FILTER,
    OPEN_PART_OF_SPEECH_FILTER,
    OPEN_ERROR_FILTER,
    CLOSE_ERROR_FILTER,
    GET_DATAFIELDS,
    ADD_MIN_AGE,
    ADD_MAX_AGE,
    OPEN_ADVANCED_FILTER,
    CLOSE_ADVANCED_FILTER,
    CLEAR_FIELDS_ADVANCED_FILTER,
} from "../actions/SearchActions";

import {
    GET_ADMIN_RECORDS,
    ADD_RECORD,
    EDIT_RECORD
} from "../actions/AdminActions";

export const initialState = {
    partsFilterIsOpen: false,
    textFilterIsOpen: false,
    advancedFilterIsOpen: false,
    errorFilterIsOpen: false,
    status: 'null',
    isFetching: false,
    partsArray: [],
    textArray:[],
    sexArray: [],
    languageArray: [],
    errorsObject:{},
    minAge: '',
    maxAge: '',
    dataFromDB:[]
};

export function searchReducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_TO_DB:
            return{
                ...state,
                status: action.payload.status,
                isFetching: action.payload.isFetching
            };
        case DATA_RECEIVED:
            return {
                ...state,
                status: action.payload.status,
                dataFromDB: action.payload.data,
                isFetching: action.payload.isFetching
            };
        case GET_DATAFIELDS:
            return {
                ...state,
                isFetching: action.payload.isFetching,
                partsArray: action.payload.partsArray,
                textArray: action.payload.textArray,
                languageArray: action.payload.languageArray,
                sexArray: action.payload.sexArray
            };

        case ADD_ERROR_TYPES:
            return {
                ...state,
                errorsObject: action.payload.errorArray
            };
        case CREATE_REQUEST:
            return {
                ...state,
                isFetching: action.payload.isFetching
            };
        case REQUEST_SUCCESS:{
            return {
                ...state,
                isFetching: action.payload.isFetching,
                dataFromDB: action.payload.data,
                status: action.payload.status
            }
        }
        case OPEN_TEXT_TYPE_FILTER:
            return {
                ...state,
                textFilterIsOpen: action.payload.isOpen,
                isFetching: action.payload.isFetching
            };
        case CLOSE_TEXT_TYPE_FILTER:
            return{
                ...state,
                textFilterIsOpen: action.payload.isOpen,
            };
        case OPEN_PART_OF_SPEECH_FILTER:
            return {
                ...state,
               partsFilterIsOpen: action.payload.isOpen,
                isFetching: action.payload.isOpen
            };
        case CLOSE_PART_OF_SPEECH_FILTER:
            return{
                ...state,
                partsFilterIsOpen: action.payload.isOpen,
                isFetching: action.payload.isFetching
            };
        case OPEN_ERROR_FILTER:
            return {
                ...state,
                errorFilterIsOpen: action.payload.isOpen,
            };
        case CLOSE_ERROR_FILTER:
            return {
                ...state,
                errorFilterIsOpen: action.payload.isOpen
            };
        case OPEN_ADVANCED_FILTER:
            return {
                ...state,
                advancedFilterIsOpen: action.payload.isOpen
            };
        case CLOSE_ADVANCED_FILTER:
            return {
                ...state,
                advancedFilterIsOpen: action.payload.isOpen
            };

        case ADD_MIN_AGE:
            return {
                ...state,
                minAge: action.payload.minAge
            };
        case ADD_MAX_AGE:
            return {
                ...state,
                maxAge: action.payload.maxAge
            };
        case CLEAR_FIELDS_ADVANCED_FILTER:
            return {
                ...state,
                maxAge: action.payload.maxAge,
                minAge: action.payload.minAge
            };

        case GET_ADMIN_RECORDS:
            return {
                ...state,
                dataFromDB: action.payload.result
            }

        case ADD_RECORD:{
            return {
                ...state,
                dataFromDB: action.payload.data
            }
        }
        case EDIT_RECORD:{
            return {
                ...state,
                dataFromDB: action.payload.result
            }
        }
        default:
                return state;

    }

}