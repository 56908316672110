import React, {Fragment} from 'react';
import User from "../User/User";
import LoginForm from "../LoginForm/LoginForm";
import {connect} from 'react-redux';
import RegistrationForm from "../RegisterForm/RegistrationForm";
import './style.css';


class EntranceContainer extends React.Component{
    constructor(props){
        super(props);
        this.state={
            formIsOpen: false
        };
        this.openLoginFormClick=this.openLoginFormClick.bind(this);
        this.registrationFormClick=this.registrationFormClick.bind(this);
    }
    openLoginFormClick(){
        if(this.state.formIsOpen==='login')
        this.setState({
            formIsOpen: false
        });
        else {
            this.setState({
                formIsOpen: 'login'
            })
        }
    }

    registrationFormClick(){
        if(this.state.formIsOpen !=='registration')
            this.setState({
                formIsOpen: 'registration'
            });
        else if(this.state.formIsOpen==='registration')
            this.setState({
                formIsOpen: false
            })
    }
    render() {
        const {user}=this.props;
        return(
            <div className={"entrance-container"}>
                {
                    user.username !=='guest' ? <User/> : <Fragment>
                        <LoginButton openLoginFormClick={this.openLoginFormClick}/>
                        <User/>
                    </Fragment>
                }
                {
                    this.state.formIsOpen==='login' ? <LoginForm
                        closeClick={this.openLoginFormClick}
                        openRegistrationClick={this.registrationFormClick}/> :
                        this.state.formIsOpen==='registration' ?
                            <RegistrationForm
                                closeClick={this.registrationFormClick}/>:
                            null
                }
            </div>
        )

    }

}


const LoginButton = props=>
            <button onClick={props.openLoginFormClick} className={"login-button"}>Вход/регистрация</button>;

const mapStateToProps=store=>{
    return{
        user: store.userReducer
    }
};

export default connect(
    mapStateToProps
)(EntranceContainer)