import {connectionData} from "../connectionData";

export const REQUEST_TO_DB='REQUEST_TO_DB';
export const DATA_RECEIVED = 'DATA_RECEIVED';
export const ADD_ERROR_TYPES='ADD_ERROR_TYPES';
export const CREATE_REQUEST='CREATE_REQUEST';
export const REQUEST_SUCCESS='REQUEST_SUCCESS';
export const OPEN_ERROR_FILTER = 'OPEN_ERROR_FILTER';
export const CLOSE_ERROR_FILTER = 'CLOSE_ERROR_FILTER';
export const CLOSE_PART_OF_SPEECH_FILTER = 'CLOSE_PART_OF_SPEECH_FILTER';
export const OPEN_PART_OF_SPEECH_FILTER = 'OPEN_PART_OF_SPEECH_FILTER';
export const OPEN_TEXT_TYPE_FILTER = 'OPEN_TEXT_TYPE_FILTER';
export const CLOSE_TEXT_TYPE_FILTER = 'CLOSE_TEXT_TYPE_FILTER';
export const OPEN_ADVANCED_FILTER = 'OPEN_ADVANCED_FILTER';
export const CLOSE_ADVANCED_FILTER = 'CLOSE_ADVANCED_FILTER';
export const GET_DATAFIELDS = 'GET_DATAFIELDS';
export const ADD_MIN_AGE='ADD_MIN_AGE';
export const ADD_MAX_AGE='ADD_MAX_AGE';
export const CLEAR_FIELDS_ADVANCED_FILTER = 'CLEAR_FIELDS_ADVANCED_FILTER';

export class SearchActions{
    constructor() {
        this.countFields = 0;
    }

    getDataFields(){
        return dispatch =>{
        if(this.countFields===0){
            fetch(connectionData.url+'search/datafields',Object.assign( connectionData.options,{method: 'GET'}))
                .then(response=> response.json())
                .then(result=>{
                    dispatch({
                        type: GET_DATAFIELDS,
                        payload: {
                            isFetching: false,
                            partsArray: result.partOfSpeech,
                            textArray: result.textType,
                            languageArray: result.language,
                            sexArray: result.sex
                        }})})
                .catch(err=>console.log(err));
            this.countFields++;
        }
    }
    }

    openTextTypeFilter() {
        return dispatch=>{
            dispatch({
                type: OPEN_TEXT_TYPE_FILTER,
                payload: {
                    isOpen: true,
                    isFetching: true
                }
            });

        }
    }
    closeTextTypeFilter() {
        return {
            type: CLOSE_TEXT_TYPE_FILTER,
            payload: {
                isOpen: false
            }
        }
    }


    openPartOfSpeechFilter() {
        return dispatch=> {
            dispatch({
                type: OPEN_PART_OF_SPEECH_FILTER,
                payload: {
                    isOpen:true,
                }
            });
        }}

    closePartOfSpeechFilter() {
        return {
            type: CLOSE_PART_OF_SPEECH_FILTER,
            payload: {
                isOpen: false,
            }
        }
    }

    openErrorFilter() {
        return {
            type: OPEN_ERROR_FILTER,
            payload:{ isOpen: true}
        }
    }

    closeErrorFilter() {
        return {
            type: CLOSE_ERROR_FILTER,
            payload: { isOpen: false}
        }

    }
     openAdvancedFilter(){
        return {
            type: OPEN_ADVANCED_FILTER,
            payload: {
                isOpen: true
            }
        }
     }

    closeAdvancedFilter(){
        return {
            type: CLOSE_ADVANCED_FILTER,
            payload: {
                isOpen: false
            }
        }
    }

    addErrorTypes(errorTypes) {
        return dispatch=>{
            dispatch({
                type: ADD_ERROR_TYPES,
                payload:{
                    errorArray: errorTypes
                }
            })
        }
    }

    inputMinAge(e){
        return dispatch=>{

            dispatch({
                type: ADD_MIN_AGE,
                payload:{
                    minAge: e.target.value
                }
            })
        }
    }

    inputMaxAge(e){
        return dispatch=>{
            dispatch({
                type: ADD_MAX_AGE,
                payload:{
                    maxAge: e.target.value
                }
            })
        }
    }

    clearFields(){
        return dispatch=>{
            dispatch({
                type: CLEAR_FIELDS_ADVANCED_FILTER,
                payload:{
                    maxAge:'',
                    minAge:''
                }
            })
        }
    }

    createRequest(dataArray) {
        return dispatch=>{
            dispatch({
                type: CREATE_REQUEST,
                payload: {
                    isFetching: true
                }
            });

            fetch(connectionData.url+'search', Object.assign({}, connectionData.options, {
                method: 'POST',
                body: dataArray
            }))
                .then(response=>response.json())
                .then(result=>{
                    dispatch({
                        type: REQUEST_SUCCESS,
                        payload:{
                            isFetching: false,
                            data: result,
                            status: 'searchDataReceived'
                        }
                    })})
                .catch(err=>console.log(err))
        }

    }

}


export const search = new SearchActions();



















