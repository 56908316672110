import React from 'react';
import {
    SubmenuHeader,
    FilterRuleButton
} from "../CommonParts/CommonParts";
import './style.css';

const ErrorSectionHeader = (props)=>{
    const {data}=props;
    return(
        <div className="error-section-header">
            <label className={'error-label-header'}>
                <input type={'checkbox'}
                       className={'error-checkbox'}
                       name={data.valueName}
                       onChange={ data.onChangeFunctionSection(data.section)}/>
                <h3 className='error-section-name'>{data.name}</h3>
            </label>
        </div>
    )
};

const ErrorSubject = (props)=>{
 const {data}=props;

  return(

      <label className="error-label"><input className="error-checkbox"
                                           type="checkbox"
                                           name={data.errorType}
                                           value={data.description}
                                           onChange={data.onChangeFunction(data.index)}
                                            checked={data.checkedValue || false}
                                           />
                                           <h3>{data.description}</h3></label>
  )
};
const AllErrorSubject=(props)=>{
    return(
        <div className="all-error-section">
            <label className="error-label">
                <input className="error-checkbox" type="checkbox" name="allErrors" onChange={props.onChangeFunctionAll}/>
                Все категории ошибок
            </label>
        </div>
    )
};

class ErrorCategoryFilter extends React.Component{
    constructor(props){
        super(props);
        this.state={
            nonorthographicErrors : [
                    {
                        name:'Написания слитно, раздельно, через дефис',
                        status: false
                    },
                    {
                        name: 'Орфоэпические ошибки (пропуски, добавления, перестановки)',
                        status: false
                    },
                    {
                        name:'Ошибки, связанные с недостаточным осмыслением текстовой ситуации',
                        status: false
                    }
                ],

            graphicErrors:[
                {
                    name:'Искажения в начертании букв (замена смежных букв, зеркальные буквы и пр.)',
                    status: false
                },
                {
                    name:'Прописные/строчные буквы',
                    status: false
                },
                {
                    name: 'Способ отражения на письме звука [ш’]',
                    status: false
                },
                {
                    name:'Двойные/одинарные согласные в аспекте отражения долготы',
                    status: false
                },
                {
                    name: 'Глухие/звонкие в сильной позиции',
                    status: false
                },
                {
                    name: 'Гласные в сильной позиции',
                    status: false
                },
                {
                    name: 'Пропуск Ъ или Ь в их разделительной функции',
                    status: false
                },
                {
                    name:'Пропуск Ь для обозначения мягкости',
                    status: false
                },
                {
                    name:'Пропуск Ь для обозначения грамматической формы слова',
                    status: false
                },
                {
                    name:'Замена, пропуск буквы Й',
                    status: false
                },
                {
                    name:'Замена гласных при обозначении твёрдости и мягкости согласных фонем',
                    status: false
                },
                {
                    name: 'Ненормативные написания гласных после шипящих и Ц',
                    status: false
                },
                {
                    name:'Прочие особенности отражения твёрдости и мягкости на письме',
                    status: false
                }
            ],

            orthographicErrors :[
                {
                    name:'Разнообразные ненормативные написания 1-го типа с использованием принципа «пишу, как слышу»',
                    status: false
                },
                {
                    name:'Разнообразные ненормативные написания 2-го типа с использованием принципа «пишу не так, как слышу»',
                    status: false
                }
            ],

            grammaticalErrors:[
                {
                    name: 'Неверная форма числа',
                    status: false
                },
                {
                    name: 'Ошибки в согласовании',
                    status: false
                },
                {
                    name:'Изменение родовой принадлежности слова',
                    status: false
                },
                {
                    name:'Нарушение глагольной формы (включая причастия и деепричастия)',
                    status: false
                },
                {
                    name:'Ошибка в координации',
                    status: false
                },
                {
                    name:'Ошибка в употреблении деепричастия (деепричастного оборота)',
                    status: false
                },
                {
                    name: 'Ошибка в управлении',
                    status: false
                },
                {
                    name:'Прочие грамматические ошибки',
                    status: false
                }
            ],

            lexicalErrors: [
                {
                    name: 'Неверное словоупотребление',
                    status: false
                },
                {
                    name:'Неустраненная (неснятая) многозначность, порождающая двоякое осмысление высказывания',
                    status: false
                },
                {
                    name:'Лексические повторы в составе одного предложения или между несколькими предложениями',
                    status: false
                }
            ],

        };
        this.onChangeFunction=this.onChangeFunction.bind(this);
        this.onChangeFunctionSection=this.onChangeFunctionSection.bind(this);
        this.onChangeFunctionAll=this.onChangeFunctionAll.bind(this);

    }
    acceptClick(){
        this.props.acceptClick({
            nonorthographicErrors: this.makeValuesArray('nonorthographicErrors'),
            grammaticalErrors: this.makeValuesArray('grammaticalErrors'),
            graphicErrors: this.makeValuesArray('graphicErrors'),
            orthographicErrors: this.makeValuesArray('orthographicErrors'),
            lexicalErrors: this.makeValuesArray('lexicalErrors')
        });
    }
    makeValuesArray(errorsState){
        return  this.state[errorsState]
            .map(item=>{
            if(item.status) return item.name;
        })
            .filter(item=>item != null);
    }
    arrayChange(state, statement){
        const arr = this.state[state];
        if(statement){
            for (let i = 0; i<arr.length; i++){
                arr[i].status=true
            }
            const obj ={};
            obj[state]=arr;
            return obj
        }
        else {
            for (let i = 0; i<arr.length; i++){
                arr[i].status=false
            }
            const obj ={};
            obj[state]=arr;
            return obj
    }
}
    onChangeFunctionSection(state){
        return e=> {
            const bool = e.target.checked;
            const obj = this.arrayChange(state, bool);
            this.setState(obj);
            }
        }


    onChangeFunctionAll(e){
        let statement = e.target.checked;
        const obj = {};
        Object.assign(obj,
            this.arrayChange('nonorthographicErrors', statement),
            this.arrayChange('orthographicErrors', statement),
            this.arrayChange('lexicalErrors', statement),
            this.arrayChange('graphicErrors', statement),
            this.arrayChange('grammaticalErrors', statement)
        );
        this.setState(obj);

   }

   clearFunction(){
       const obj = {};
       Object.assign(obj,
           this.arrayChange('nonorthographicErrors', false),
           this.arrayChange('orthographicErrors', false),
           this.arrayChange('lexicalErrors', false),
           this.arrayChange('graphicErrors', false),
           this.arrayChange('grammaticalErrors', false)
       );
       this.setState(obj);

   }
    onChangeFunction(index){
        return e=>{
            const tmp = this.state[e.target.name];
            const err= {};

            if (e.target.checked) {
                tmp[index].status =true;
                err[e.target.name] = tmp;
                    this.setState(err);
        } else {
                tmp[index].status = false;
                err[e.target.name] = tmp;
                this.setState(err);
        }
        }
    }


    render() {
    const buttonData=[
        {
            name: 'acceptButton',
            description: 'Принять',
            clickFunction: ()=>{
                this.acceptClick();
                this.props.closeClick()
            }
        },
        {
            name: 'clearButton',
            description: 'Очистить',
            clickFunction: ()=>this.clearFunction()
        },
        {
            name: 'closeButton',
            description: 'Отмена',
            clickFunction: this.props.closeClick
        }
    ];

    const commonParts={
        onChangeFunction: this.onChangeFunction,
        onChangeFunctionSection: this.onChangeFunctionSection,
    };
    const nonorthographicData={
        sectionName: "Неорфографические ошибки",
        valueName: 'nonorthographicAll',
        valueError: 'nonorthographicErrors',
        errorsArray: this.state.nonorthographicErrors,
    };

    const orthographicData ={
        sectionName: "Орфографические ошибки",
        valueName: 'orthographicAll',
        valueError: 'orthographicErrors',
        errorsArray: this.state.orthographicErrors,
    };

    const lexicalData ={
        sectionName: 'Лексические ошибки',
        valueName: 'lexicalAll',
        valueError: 'lexicalErrors',
        errorsArray: this.state.lexicalErrors,
    };

    const graphicData={
        sectionName: 'Графические ошибки',
        valueName: 'graphicAll',
        valueError: 'graphicErrors',
        errorsArray: this.state.graphicErrors,
    };

    const grammaticalData={
        sectionName: 'Грамматические ошибки',
        valueName: 'grammaticalAll',
        valueError: 'grammaticalErrors',
        errorsArray: this.state.grammaticalErrors,
        onChangeFunction: this.onChangeFunction
    };


  return(
      this.props.isOpen?
      <div className={"error-category-container"}>
          <SubmenuHeader name={"Категория ошибки"} closeClick={this.props.closeClick}/>
          <div>
              <ErrorSection data={Object.assign({},nonorthographicData, commonParts)}/>
              <ErrorSection data={Object.assign({}, grammaticalData, commonParts)}/>
          </div>
                <ErrorSection data={Object.assign({}, graphicData, commonParts)}/>
            <div>
                <ErrorSection data={Object.assign({}, lexicalData, commonParts)}/>
                <ErrorSection data={Object.assign({},orthographicData, commonParts)}/>
                <AllErrorSubject onChangeFunctionAll={this.onChangeFunctionAll}/>
                <div className="filter-button-container">
                    {
                        buttonData.map(item=>
                            <FilterRuleButton key={item.name} buttonName={item.description} clickFunction={item.clickFunction}/>
                        )
                    }
                </div>
            </div>

      </div>:null
  )}
}


const ErrorSection = (props)=>{
    const {data}=props;
  return(
      <div className="error-section-container">

          <ErrorSectionHeader data ={
              {
                  name:data.sectionName,
                  valueName: data.valueName,
                  section: data.valueError,
                  onChangeFunctionSection: data.onChangeFunctionSection
              }
          }/>
          {data.errorsArray.map((error, index)=>{

              return <ErrorSubject key={index} data ={{
                  index : index,
                  errorType: data.valueError,
                  description: error.name,
                  onChangeFunction: data.onChangeFunction,
                  checkedValue: error.status,
              }}/>
          })}

      </div>
  )
};



export {ErrorCategoryFilter}