import React, {Suspense} from 'react';
import {
    StaticHeader,
    StickyHeader,
    BreadCrumbs,
    Footer
} from '../commonComponents'
import {connect} from 'react-redux'
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {MainPage,
        AboutPage,
        HelpPage
} from '../Pages';
import StatisticsPage from '../../containers/Statistics/Statistics';
import SearchPage from '../../containers/Search/Search';
import './style.css'

const AdminPanel = React.lazy(()=>import('../AdminPanel/AdminPanel'))

const MainContainer = ({roleId}) =>{
    return<>
            <BrowserRouter>
            <StaticHeader/>
            <StickyHeader/>
            <MainWindow roleId={roleId}/>
            </BrowserRouter>
            <Footer/>
        </>

};

const MainWindow =({roleId})=>{
    const commonRoutes= [{
        path: '/',
        exact: true,
        component: <MainPage/>,
        breadCrumbs: () => <span>Главная</span>
    },
        {
            path: '/search',
            component: <SearchPage/>,
            breadCrumbs: () => <span>Поиск в базе данных</span>
        },
        {
            path: '/statistics',
            component: <StatisticsPage/>,
            breadCrumbs: () => <span>Статистика</span>
        },
        {
            path: '/help',
            component: <HelpPage/>,
            breadCrumbs: () => <span>Помощь</span>
        },
        {
            path: '/about',
            component: <AboutPage/>,
            breadCrumbs: () => <span>О нас</span>
        }];


    const rotes= {
        4: commonRoutes,
        3: commonRoutes,
        2: commonRoutes.concat([
            {
                path: '/administration/editing',
                component: <Suspense fallback={<div>Загрузка...</div>}>
                    <AdminPanel roleId={2}/>}/></Suspense>,
                breadCrumbs: ()=><span>Администрирование</span>
        }]),
        1: commonRoutes.concat([
            {
                path: ['/administration/editing', '/administration/users'],
                component: <Suspense fallback={<div>Загрузка...</div>}>
                    <AdminPanel roleId={1}/></Suspense>,
                breadCrumbs: ()=><span>Администрирование</span>
            },
        ])
    };
    return <div className="main-window-container">
            <Switch>
                <BreadCrumbs>{
                    rotes[roleId || 4].map((route)=>(
                        <Route key={route.path} path={route.path} exact={route.exact} component={route.breadCrumbs}/>
                    ))
                }
                </BreadCrumbs>
            </Switch>
                <Switch>
                {rotes[roleId].map((route)=> <Route key={route.path}
                           path={route.path}
                           exact ={route.exact}
                           children = {route.component}/>
                )}

            </Switch>
        </div>

};

const mapStateToProps = state=>{
    return {
        roleId: state.userReducer.idAccessLevel
    }
}

export default connect(mapStateToProps, null)(MainContainer)