import React, {Fragment} from 'react';
import {registerUser} from "../../actions/UserAction";
import {connect} from 'react-redux';
import './style.css'



class  RegistrationForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            username: {
                value: '',
                state: 'default',
                hint: null
            },
            password: {
                value: '',
                state: 'default',
                hint: null
            },
            password2: {
                value: '',
                state: 'default',
                hint: null
            },
            email:{
                value: '',
                state: 'default',
                hint: null
            },
            surname: {
                value: '',
                state: 'default',
                hint: null
            },
            name: {
                value: '',
                state: 'default',
                hint: null
            }


        };
        this.registrationClick=this.registrationClick.bind(this);

    }


 onChangeFunction(newstate){
     return e => {
            const state = {};
            state[newstate] = {...this.state[newstate],
                value: e.target.value
            };
            this.setState(state);
        }

     }
     validateEmail (email) {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return expression.test(String(email).toLowerCase())
    }


     onChangeFunctionPassword(newstate){
        return e=>{
            const state ={};
            let value = e.target.value.trim().replace(/[^A-z0-9_.]/g, '');
            state[newstate] = {...this.state[newstate],
                value: value
            };
            this.setState(state);
        }
     }
     registrationClick(){

        let correct = true;
        let samePasswords=false;
        for(let key in this.state){

            if(this.state[key].value.length> 0 ) {
                const state = {};
                state[key] = {...this.state[key],
                    state: true
                };
                this.setState(state);
            }
            else {
                const state = {};
                state[key] = {...this.state[key],
                    state: false
                };
                correct=false;
                this.setState(state);


        }}
        if(this.state.password.value === this.state.password2.value) samePasswords = true;
        else {
            this.setState({
                password:{
                    ...this.state.password,
                    state: false
                },
                password2:{
                    ...this.state.password2,
                    state: false
                }
            });
        }

        if(!this.validateEmail(this.state.email.value)){
            this.setState({
                email:{
                    ...this.state.email,
                    state: false
                    }
            });
            correct=false;
        }
        if(correct && samePasswords){
            const data = {
                userNum: this.props.user.userNum,
                username: this.state.username.value,
                password: this.state.password.value,
                surname: this.state.surname.value,
                name: this.state.name.value,
                email: this.state.name.value
            };
            this.props.registration(data);
        }



     }


    render() {
        const passwordHint = {
            description:'Для пароля можно использовать только буквы латинского алфавита, цифры и символы: _.. Длина должна составлять не менее 8 символов. '
        };

        const emailHint = {
            description:''
        };
        const wrongPasswordS = {
            description: 'Пароли не совпадают'
        };

        if(!this.state.password.state && !this.state.password2.state){

        }

        const object=[
            {
                head: 'Имя пользователя',
                type: 'text',
                name: 'username',
                value: this.state.username.value,
                onChangeFunction: this.onChangeFunction('username'),
                onFocus: null,
                inputState: this.state.username.state
            },
            {
                head: 'Пароль',
                type: 'password',
                name: 'password',
                value: this.state.password.value,
                onChangeFunction: this.onChangeFunctionPassword('password'),
                onFocus: passwordHint,
                inputState: this.state.password.state
            },
            {
                head: 'Подтверждение пароля',
                type: 'password',
                name: 'password2',
                value: this.state.password2.value,
                onChangeFunction: this.onChangeFunctionPassword('password2'),
                onFocus: null,
                inputState: this.state.password2.state
            },
            {
                head: 'E-mail',
                type: 'text',
                name: 'email',
                value: this.state.email.value,
                onChangeFunction: this.onChangeFunction('email'),
                onFocus: emailHint,
                inputState: this.state.email.state
            },
            {
                head: 'Фамилия',
                type: 'text',
                name: 'surname',
                value: this.state.surname.value,
                onChangeFunction: this.onChangeFunction('surname'),
                onFocus: null,
                inputState: this.state.surname.state
            },
            {
                head: 'Имя',
                type: 'text',
                name: 'name',
                value: this.state.name.value,
                onChangeFunction: this.onChangeFunction('name'),
                onFocus: null,
                inputState: this.state.name.state
            },

        ];
        return(
            <div className={"registration-form-container"}>
                <div className={'registration-form-header'}>
                    <h3 className={"registration-name"}>Регистрация</h3>
                    <button className={'registration-close-button'} onClick={this.props.closeClick}>×</button>
                </div>
                <div className={'registration-container'}>
                    {
                        object.map(item=> <RegObject key={item.name} data={item}/>)
                    }
                    <button className={'registration-button'} onClick={this.registrationClick}>Регистрация</button>
                </div>


            </div>
        )
    }
}

const HintObject = props =>{
    const data = props.data;
    return <div className={'hint-container'}><div className={'hint-window'}>
        <h3 className={'hint-text'}>{data.description}</h3>
    </div></div>
};

class RegObject extends React.Component{
    constructor(props){
        super(props);
        this.state={
            hint: null
        };
        this.onFocusFunction=this.onFocusFunction.bind(this);
        this.onBlurFunction=this.onBlurFunction.bind(this);
    }

    onFocusFunction(){

        if(this.props.data.onFocus!==null)
        this.setState({
            hint: <HintObject data={this.props.data.onFocus}/>
        })

    }
    onBlurFunction(){
        this.setState({
            hint: null
        })

    }
    render() {
        const {data} = this.props;
        let className = 'registration-input';
        if (data.inputState && data.inputState !== 'default') className = 'registration-input registration-input-is-right';
        else if (data.inputState !== 'default') className = 'registration-input registration-input-is-wrong';

        return <Fragment>

            <h3 className={'registration-section-text'}>{data.head}</h3>
           {this.state.hint}
            <input className={className}
                   type={data.type}
                   onChange={data.onChangeFunction}
                   value={data.value}
                   name={data.name}
                   onFocus={this.onFocusFunction}
                   onBlur={this.onBlurFunction}/>

        </Fragment>
    }
}

const mapStateToProps= store=>{
    return{
            user: store.userReducer
    }
};

const mapDispatchToProps=dispatch=>({
    registration: data=>dispatch(registerUser(data))

});

export default connect(
    mapStateToProps,
    mapDispatchToProps)(RegistrationForm);