import React from 'react';

import './style.css';

const Footer = ()=>{
  return(
      <div className="footer-container">
          <div className="footer-green-stripe"/>
          <div className="footer-black-stripe"/>
      </div>
  )

};



export {Footer};