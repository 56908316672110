import {connectionData} from "../connectionData";

export const USER_NUMBER_REQUEST = 'USER_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const REGISTRATION_REQUEST= 'REGISTRATION_REQUEST';
export const USER_NUMBER_RECEIVED='USER_NUMBER_RECEIVED';
export const USER_DATA_RECEIVED ='USER_DATA_RECEIVED';
export const USER_RELOAD = 'USER_RELOAD';

export function getUserNum() {
    return dispatch=>{
        dispatch({
            type: USER_NUMBER_REQUEST,
            payload:
                {
                    status: 'getting user number',
                    isFetching: true
                }
        });
        fetch(connectionData.url, connectionData.options)
            .then(response=>response.json())
            .then(result=>
            {
                sessionStorage.setItem('userData', JSON.stringify(result));
            dispatch({
                type:USER_NUMBER_RECEIVED,
                payload: {
                    userNum: result.userNum,
                    status: 'user number received',
                    isFetching: false,
                }})})
            .catch(err=>console.log(err))


    }

}

export function loginUser(data) {
    return dispatch=>{
        dispatch({
            type: LOGIN_REQUEST,
            payload: {
                status: 'Login attempt',
                isFetching: true
            }
        });
        fetch(connectionData.url+'login', Object.assign({}, connectionData.options, {
            method: 'POST',
            body: JSON.stringify(data)
        }))
            .then(response=>response.json())
            .then(result=>{
                sessionStorage.setItem('userData', JSON.stringify(result));

                dispatch({
                type:USER_DATA_RECEIVED,
                payload:{
                    username: result.username,
                    password: result.password,
                    idAccessLevel: result.id_access_level,
                    accessLevel: result.accessLevel,
                    surname: result.surname,
                    name: result.surname,
                    email: result.email,
                    userNum: result.userNum,
                    status: 'userLogin',
                    isFetching: false,
                }
            })}
            )
    }

}

export function userReload(userData) {
    return dispatch=>{
        dispatch(
            {
                type: USER_RELOAD,
                payload:{
                    userData: userData
                }
            }
        )
    }

}

export function deleteUserNum(userNum) {
    return

}

export function registerUser(data) {
    return dispatch=>{

        dispatch({
            type: REGISTRATION_REQUEST,
            payload: {
                status: 'registration request',
                isFetching: true
            }
        });
        fetch(connectionData.url+'registration',Object.assign({}, connectionData.options, {
            method: 'POST',
            body: JSON.stringify(data)
        }))
            .then(response=>response.json())
            .then(result=>
            {
                sessionStorage.setItem('userData', JSON.stringify(result));
                dispatch({
                type: USER_DATA_RECEIVED,
                payload:{
                    username: result.username,
                    password: result.password,
                    idAccessLevel: result.id_access_level,
                    accessLevel: result.accessLevel,
                    surname: result.surname,
                    name: result.surname,
                    email: result.email,
                    userNum: result.userNum,
                    status: 'registration success',
                    isFetching: false,
                }
            })})
            .catch(err=>console.log(err))
    }
}