import React from 'react';
import EntranceContainer from "../../../containers/EntranceContainer/EntranceContainer";
import './style.css';

const StaticHeader =() =>{
        return(
            <div className="header-background">
                <div className="header-container">
               <Logo/>
               <EntranceContainer/>
                </div>
            </div>
        )
};


const Logo = () => {
    return(
        <div className="logo-background">
            <span className="logo-text">FCLB</span>
        </div>
    )
};


export  {StaticHeader};