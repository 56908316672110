import React from 'react';
import {connect} from 'react-redux';
import {loginUser} from "../../actions/UserAction";


import './style.css'




class  LoginForm  extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isPassword: true,
            login:'',
            password:''
        };
        this.onChangePassword=this.onChangePassword.bind(this);
        this.onChangePassInput=this.onChangePassInput.bind(this);
        this.onChangeLoginInput=this.onChangeLoginInput.bind(this);
        this.loginClick=this.loginClick.bind(this);
    }
    onChangePassword(e){
        if(e.target.checked){
            this.setState({
                isPassword:false,

            })
        }
        else{
            this.setState({
                isPassword:true
            })
        }
    }
    onChangePassInput(e){
        this.setState({
            password: e.target.value
        })
}
    onChangeLoginInput(e){
        this.setState({
            login:e.target.value
        })
    }

    loginClick(){
        const data={
            userNum: this.props.user.userNum,
            username: this.state.login,
            password: this.state.password
        };
        this.props.loginUser(data);
    }

    render() {
        return(
            <div className={"login-form-container"}>
                <button className={'close-login-button'} onClick={this.props.closeClick}>×</button>
                <div className={'login-form'}>
                    <h3 className={'log-form-text'}>Логин или E-mail</h3>
                    <input type={'text'} className={'login-input'}
                           onChange={this.onChangeLoginInput}
                    value={this.state.login}/>
                    <h3 className={'log-form-text'}>Пароль</h3>
                    <div className={"password-section"}>
                    <input type={this.state.isPassword?'password': 'text'} onChange={this.onChangePassInput} className={'password-input'} value={this.state.password}/>
                    <label className={'password-button'}>
                        <input type={'checkbox'} name={'isPassword'} onChange={this.onChangePassword} className={'pass-checkbox'}/>
                        Показать</label></div>
                    <button className={'forgotten-pass'}>Забыли пароль?</button>
                    <button className={'enter-button'} onClick={this.loginClick}>Войти</button>
                    <hr className={'login-line'}/>
                    <button onClick={this.props.openRegistrationClick} className={'change-form-registration'}>Регистрация</button>

                </div>
                
            </div>
        )
    }
}

const mapStateToProps= store=>{
    return{
       user: store.userReducer
    }
};

const mapDispatchToProps=dispatch=>({
    loginUser:data=>dispatch(loginUser(data))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm)