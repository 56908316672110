import {
    GET_ADMIN_FIELDS,
    GET_LANGUAGE,
    SET_CHILD_VALUE_FIELD,
    ONCHANGE_CHILD_FIELD,
    ONCHANGE_RECORD_FIELD,
    SET_RECORD_VALUE_FIELD,
    GET_RECORD,
    CLEAR_FIELDS,
    ONCHANGE_FIELD,
    GET_USERS, SET_ROLE

} from "../actions/AdminActions";


const initialState={
    createOperation: true,
    search: '',
    record:{
        id: 0,
        textType: 0,
        partOfSpeech: 0,
        errorCategory: 0,
        errorType: 0,
        child: 1,
        age: '',
        year: '',
        word: '',
        correct:'',
        context1: '',
        context2:''
    },
    child:{
        name: '',
        sex: 0,
        lang: 1
    },
    errorCategory: [],
    errorType: [],
    partOfSpeech: [],
    textType: [],
    children: [],
    langArray: [],
    users: []
}


export const adminReducer = (state=initialState, action)=>{
    switch (action.type) {

        case GET_LANGUAGE: {
            return {
                ...state,
                langArray: action.payload.array
            }
        }
        case SET_CHILD_VALUE_FIELD:{
            return {
                ...state,
                child: {
                    ...state.child,
                    [action.payload.field]: action.payload.value
                }
            }}

        case SET_RECORD_VALUE_FIELD:{
            return {
                ...state,
                record: {
                    ...state.record,
                    [action.payload.field]: action.payload.value
                }
            }
        }

        case ONCHANGE_CHILD_FIELD:{
            return {
                ...state,
                child: {
                    ...state.child,
                    [action.payload.field]: action.payload.value
                }
            }
        }

        case ONCHANGE_RECORD_FIELD:{
           return {
               ...state,
               record: {
                   ...state.record,
                   [action.payload.field]: action.payload.value
               }
           }
        }
        case GET_ADMIN_FIELDS:{
            const obj = action.payload.object;
            return {
                ...state,
                errorCategory: obj['errorCategoryCollection'].map(item=>item.value),
                errorType: obj['errorTypeCollection'].map(item=>item.value),
                partOfSpeech: obj['partOfSpeechCollection'].map(item=>item.value),
                textType: obj['textTypeCollection'].map(item=>item.value),
                children: obj['childrenCollection'],
            }
        }
        case GET_RECORD:{
            return {
                ...state,
                record: {
                    id: action.payload.result[0].number,
                    textType: action.payload.result[0].id_text_type,
                    partOfSpeech: action.payload.result[0].id_part_of_speech,
                    errorCategory: action.payload.result[0].id_error_category,
                    errorType: action.payload.result[0].id_error_type,
                    child: action.payload.result[0].id_child,
                    age: +action.payload.result[0].age,
                    year: action.payload.result[0].year,
                    word: action.payload.result[0].word,
                    correct:action.payload.result[0].correct,
                    context1: action.payload.result[0].context1,
                    context2: action.payload.result[0].context2
                },
                createOperation: false
            }
        }

        case GET_USERS:{
            return {
                ...state,
                users: action.payload.result
            }
        }
        case SET_ROLE:{
            return {
                ...state,
                users: action.payload.result
            }
        }

        case ONCHANGE_FIELD:{
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }
        }

        case CLEAR_FIELDS:{
            return {
                ...state,
                child: {
                    name: '',
                    sex: 0,
                    lang: 1
                },
                record: {
                    id: 0,
                    textType: 0,
                    partOfSpeech: 0,
                    errorCategory: 0,
                    errorType: 0,
                    child: 1,
                    age: '',
                    year: '',
                    word: '',
                    correct:'',
                    context1: '',
                    context2:''
                }
            }
        }

        default:
            return state
    }
}