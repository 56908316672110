import React from 'react';
import {NavLink} from "react-router-dom";
import './style.css';

const StickyHeader = ()=>{
    return(
        <div className="sticky-header">
            <div className="header-container">
                <ul className="menu">
                    <NavLink to={"/"} className="tab-text"
                             activeClassName={'tab-text-is-active'}
                             isActive={(match, location) => location.pathname==="/"}>{"Главная"}</NavLink>
                    <NavLink to={"/search"}
                             className="tab-text"
                             activeClassName={'tab-text-is-active'}
                             isActive={(match, location) => location.pathname.includes("/search")}>{"Поиск"}</NavLink>
                    <NavLink to={"/statistics"}
                             className="tab-text"
                             activeClassName={'tab-text-is-active'}
                             isActive={(match, location) => location.pathname.includes("/statistics")}>{"Статистика"}</NavLink>
                    <NavLink to={"/help"}
                             className="tab-text"
                             activeClassName={'tab-text-is-active'}
                             isActive={(match, location) => location.pathname.includes("/help")}>{"Помощь"}</NavLink>
                    <NavLink to={"/about"}
                             className="tab-text"
                             activeClassName={'tab-text-is-active'}
                             isActive={(match, location) => location.pathname.includes("/about")}>{"О нас"}</NavLink>
                </ul>
            </div>
        </div>
    )

};

export {StickyHeader};