import React from 'react';

import {connect} from 'react-redux';
import {search} from "../../../actions/SearchActions";
import {
    SubmenuHeader,
    FilterRuleButton
} from '../CommonParts/CommonParts';
import './style.css';

class AdvancedFilter extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            render: false
        };
        this.clearFunction=this.clearFunction.bind(this);
    }


    clearFunction(){

        this.props.clearFields();

        this.props.languageArray.forEach(item=>{
            item.status=false;
        });

        this.props.sexArray.forEach(item=>{
            item.status=false;
        });
        this.setState({
            render: !this.state.rander
        })
    }

 render() {

     const buttonData=[
         {

             name: 'acceptButton',
             description: 'Принять',
             clickFunction: ()=>{
                 this.props.closeFilter()
             }

         },
         {
             name: 'clearButton',
             description: 'Очистить',
             clickFunction: ()=>{
                 this.clearFunction();
             }
         },
         {
             name: 'cancelButton',
             description: 'Отмена',
             clickFunction: ()=>{
                 this.clearFunction();
                 this.props.closeFilter()
             }
     }];

     return this.props.isOpen ? <div className={'advanced-filter-container'}>
         <SubmenuHeader name={'Дополнительно'} closeClick={this.props.closeFilter}/>
            <AgeForm data={{
                minAge: this.props.minAge,
                onChangeMin: this.props.onChangeMin,
                maxAge: this.props.maxAge,
                onChangeMax: this.props.onChangeMax
            }}/>
            <Language langArray={this.props.languageArray}/>
            <Sex sexArray={this.props.sexArray}/>
         <div className={'filter-button-container'}>
             {
                 buttonData.map(item=>
                     <FilterRuleButton key={item.name} buttonName={item.description} clickFunction={item.clickFunction}/>
                 )
             }
         </div>
     </div> : null
 }
}

const AgeForm=({data})=>{

    return <div className={'age-form-container'}>
        <h3>Возраст</h3>
        <input type={'text'} value={data.minAge} onChange={data.onChangeMin} pattern={[0-9]} placeholder={'с'}/>
        <input type={'text'} value={data.maxAge} onChange={data.onChangeMax} pattern={[0-9]} placeholder={'по'}/>
    </div>

};


// TODO: Написать НОС


class Language extends React.Component {

    constructor(props) {
    super(props);
    this.state={
        render: false
    }
}


    onChange(index){
        return e=>{
            this.props.langArray[index].status = !!e.target.checked;
            this.setState({
                render: !this.state.render
            });

        }
    }

    render() {
    return <div className={'language-container'}>
        <h3>Родной язык</h3>
      {
          this.props.langArray.map((item, index)=>{
              return <label className={'part-label'}>
                  <input className={'part-checkbox'}
                         value={item.value}
                         type={'checkbox'}
                         name={'partOfSpeech'}
                         checked={item.status}
                         onChange={this.onChange(index)}/>
                  {item.value}
              </label>

          })
      }

  </div>
    }
}

class Sex extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            render: false
        }
    }

    onChange(index){
        return e=>{
            this.props.sexArray[index].status = !!e.target.checked;
            this.setState({
                render: !this.state.render
            })
        }
    }

    render() {
        return <div className={'sex-container'}>
            <h3>Пол</h3>
            {
                this.props.sexArray.map((item,index)=>{
                    return <label className={'part-label'}>
                        <input className={'part-checkbox'}
                               value={item.value}
                               type={'checkbox'}
                               name={'partOfSpeech'}
                               checked={item.status}
                               onChange={this.onChange(index)}/>
                        {item.value}
                    </label>
                })

            }
        </div>
    }
}

const mapStateToProps = state =>{
    return {
        maxAge: state.searchReducer.maxAge,
        minAge: state.searchReducer.minAge,
        languageArray: state.searchReducer.languageArray,
        sexArray: state.searchReducer.sexArray,
        isOpen: state.searchReducer.advancedFilterIsOpen
    }
};

const mapDispatchToProps = dispatch=>{
    return {
        closeFilter: ()=>dispatch(search.closeAdvancedFilter()),
        clearFields: ()=>dispatch(search.clearFields()),
        onChangeMin: e=>dispatch(search.inputMinAge(e)),
        onChangeMax: e=>dispatch(search.inputMaxAge(e))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
    )(AdvancedFilter)