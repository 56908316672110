import {connectionData} from "../connectionData";

export const STATISTICS_REQUEST = 'STATISTICS_REQUEST';
export const STATISTICS_REQUEST_SUCCESS = 'STATISTICS_REQUEST_SUCCESS';
export const STATISTICS_REQUEST_FAILED= 'STATISTICS_REQUEST_FAILED';
let count = 0;
export function getStatistics() {
    if(count ===0)return dispatch=>{
        dispatch({
            type: STATISTICS_REQUEST,
            payload: {
                status: 'request',
                isFetching: true
            }
        });
        fetch(connectionData.url + 'statistics', Object.assign(connectionData.options, {
            method: 'GET'
        }))
            .then(response=>response.json())
            .then(result=>{
                dispatch({
                   type: STATISTICS_REQUEST_SUCCESS,
                   payload: {
                       status: 'request_success',
                       isFetching: false,
                       countOfRecords: result[0][0]['COUNT(*)'],
                       countOfChildren: result[1][0]['COUNT(*)'],
                       sex: result[2],
                       age: result[3],
                       language:result[4],
                       textTypes:result[5],
                       parts:result[6]

                   }
                });
            })
            .catch(err=>{
                console.log(err);
                dispatch({
                    type: STATISTICS_REQUEST_FAILED,
                    payload: {
                        status: err.message,
                        isFetching: false
                    }
                })
            });
    }
    
}