import {
    STATISTICS_REQUEST_SUCCESS,
    STATISTICS_REQUEST,
    STATISTICS_REQUEST_FAILED
} from "../actions/StatisticsActions";

const initialState ={
    status: 'start',
    isFetching: false,
    countOfRecords: 0,
    countOfChildren: 0,
    sex: [],
    age: [],
    language:[],
    textTypes:[],
    parts:[]
};

export function statisticsReducer(state=initialState, action) {
    switch (action.type) {
        case STATISTICS_REQUEST:
            return {
                ...state,
                status: action.payload.status,
                isFetching: action.payload.isFetching
            };
        case STATISTICS_REQUEST_SUCCESS:
            return {
                ...state,
                status: action.payload.status,
                isFetching: action.payload.isFetching,
                countOfRecords: action.payload.countOfRecords,
                countOfChildren: action.payload.countOfChildren,
                sex: action.payload.sex,
                age: action.payload.age,
                language:action.payload.language,
                textTypes:action.payload.textTypes,
                parts:action.payload.parts
            };
        case STATISTICS_REQUEST_FAILED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: action.payload.isFetching
            };
        default:
            return state

    }

}