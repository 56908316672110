import React from 'react';
import './style.css';


const SubmenuHeader =props=>
{
        return(
            <div className="submenu-header-container">
                <h3 className='submenu-name'>{props.name}</h3>
                <button className="close-button" onClick={()=>props.closeClick()}>×</button>
            </div>
        )
};

const FilterRuleButton = props =>{
        return(<button onClick={props.clickFunction} className="filter-rule-button">{props.buttonName}</button>)
    };



export {
    SubmenuHeader,
    FilterRuleButton
}