import {connectionData} from "../connectionData";

export const GET_ADMIN_RECORDS = 'GET_ADMIN_RECORDS';
export const GET_ADMIN_FIELDS = 'GET_ADMIN_FIELDS';
export const GET_LANGUAGE = 'GET_LANGUAGE';
export const ADD_RECORD = 'ADD_RECORD';
export const SET_CHILD_VALUE_FIELD = 'SET_CHILD_VALUE_FIELD';
export const ONCHANGE_CHILD_FIELD = 'ONCHANGE_CHILD_FIELD';
export const ADD_CHILD = 'ADD_CHILD';
export const ONCHANGE_RECORD_FIELD='ONCHANGE_RECORD_FIELD';
export const SET_RECORD_VALUE_FIELD = 'SET_LANGUAGE_FIELD';
export const GET_RECORD='GET_RECORD';
export const EDIT_RECORD='EDIT_RECORD';
export const CLEAR_FIELDS='CLEAR_FIELDS';
export const ONCHANGE_FIELD='ONCHANGE_FIELD';
export const GET_USERS='GET_USERS';
export const SET_ROLE='SET_ROLE';

class AdminActions {
    getRecords(word){

        let url = word ? 'administration/database/editing?wordForm='+word: 'administration/database/editing?wordForm=';
        return dispatch=> {
            fetch(connectionData.url + url,
                Object.assign(connectionData.options, {method: 'GET'}))
                .then(response => response.json())
                .then(result=>{
                    dispatch({
                        type: GET_ADMIN_RECORDS,
                        payload:{
                            result
                        }
                    })
                })
                .catch(err=>console.log(err))
        }
    }

    deleteRecord(num){
        return dispatch=>{
            fetch(connectionData.url+ 'administration/database/delete?num='+num,
                Object.assign(connectionData.options,
                    {
                        method: 'DELETE'
                    }))
                .then(response=>response.json())
                .then(result=>{
                    dispatch({
                        type: GET_ADMIN_RECORDS,
                        payload: {
                            result
                        }
                    })
                })
        }
    }


    getLanguage(){
        return dispatch=>{
            fetch(connectionData.url+'administration/database/language',
                    Object.assign(connectionData.url, {
                        method: 'GET'
                    })
                )
                .then(response=>response.json())
                .then(result=>dispatch({
                    type: GET_LANGUAGE,
                    payload:{
                        array: result
                    }
                }))
                .catch(err=>console.log(err))
        }
    }
    addRecord(data){
        return dispatch=>{
            fetch(connectionData.url+ 'administration/database/addRecord',
                Object.assign(connectionData.options,{
                    method: 'POST',
                    body: JSON.stringify(data)
                }))
                .then(response=>response.json())
                .then(result=>{
                    alert(JSON.stringify(result))
                    dispatch({
                        type: ADD_RECORD,
                        payload:{
                            data: result
                        }
                    })
                })
                .catch(err=>console.log(err))
        }
    }

    getRecord(num){
        return dispatch=>{
            fetch(connectionData.url+'administration/database/getRecord?num='+num)
                .then(response=>response.json())
                .then(result=>{
                    dispatch({
                    type: GET_RECORD,
                    payload:{
                        result
                    }
                })})
        }
    }

    setChildValueField(value, field){
        return dispatch=>{
            dispatch({
                type: SET_CHILD_VALUE_FIELD,
                payload:{
                    field,
                    value
                }
            })
        }
    }

    setRecordValueField(value, field){
        return dispatch=>dispatch({
            type: SET_RECORD_VALUE_FIELD,
            payload: {
                field,
                value
            }
        })
    }


    onChangeRecordFields(e, field){
        return dispatch=>{
            dispatch({
                type: ONCHANGE_RECORD_FIELD,
                payload:{
                    field,
                value: e.target.value}
            })
        }
    }

    onChangeChildField(e, field){
        return dispatch=>{
            dispatch({
                type: ONCHANGE_CHILD_FIELD,
                payload:{
                    field,
                    value: e.target.value}
            })
        }
    }

    addChild(data){
        return dispatch=>{
            fetch(connectionData.url+'administration/addChild',
                Object.assign(connectionData.options, {
                    method: 'POST',
                    body: JSON.stringify(data)
                }))
                .then(response=>response.json())
                .then(result=>dispatch({
                    type: ADD_CHILD,
                    payload: {
                        children: result
                    }
                }))

        }
    }

    getFields(){
        return dispatch=>{
            fetch(connectionData.url+ 'administration/database/fields', Object.assign(connectionData.options, {
                method: 'GET'
            }))
                .then(response=>response.json())
                .then(result=>{
                    dispatch({
                        type: GET_ADMIN_FIELDS,
                        payload: {
                            object: result
                        }
                })})
                .catch(err=>console.log(err))
        }
    }

    editRecord(data){
        return dispatch=>{
            fetch(connectionData.url+'administration/database/editRecord', Object.assign(connectionData.options, {
                method: 'PUT',
                body: JSON.stringify(data)

            }))
                .then(response=>response.json())
                .then(result=>{
                    dispatch({
                        type: EDIT_RECORD,
                        payload: {
                            result
                        }
                    })
                })
        }
    }

    getUsers(){
        return dispatch=>{
            fetch(connectionData.url+'administration/users', Object.assign(connectionData.options,{
                method: 'GET'
            }))
                .then(response=>response.json())
                .then(result=>{
                    dispatch({
                    type: GET_USERS,
                    payload: {
                        result
                    }
                })})
        }
    }

    setRole(roleId, id){
        return dispatch=>{
            fetch(connectionData.url+ 'administration/users',
                Object.assign(connectionData.options, {
                    method: 'PUT',
                    body: JSON.stringify({
                        id,
                        roleId
                    })
            }))
                .then(response=>response.json())
                .then(result=>dispatch({
                    type: SET_ROLE,
                    payload:{
                        result
                    }
                }))
                .catch(err=>console.log(err))
        }
    }

    onChangeField(e, field){
        return dispatch=>dispatch({
            type: ONCHANGE_FIELD,
            payload: {
                field,
                value: e.target.value
            }
        })
    }
    clearField(){
        return dispatch=>dispatch({
            type: CLEAR_FIELDS
        })
    }
}

export const AdminApi = new AdminActions();