import React from 'react';
import {
    SubmenuHeader,
    FilterRuleButton
} from '../CommonParts/CommonParts';
import {connect} from 'react-redux';
import './style.css';


const PartOfSpeech = props=>{
    const {data} = props;
  return <label className={'part-label'}>
        <input className={'part-checkbox'}
               value={data.value}
               type={'checkbox'}
               name={'partOfSpeech'}
               checked={data.status}
               onChange={data.onChangeFunction(data.index)}/>
        {data.value}
        </label>
};

class PartOfSpeechFilter extends React.Component{
    constructor(props){
        super(props);
        this.state={
            render: false
        };
        this.onChangeFunction=this.onChangeFunction.bind(this);

}
    onChangeFunction(index) {
      return  e=>{
          this.props.partsArray[index].status = !!e.target.checked;
          this.setState({
              render: !this.state.render
          })
       }
    }

    clearFunction(){
        this.props.partsArray.forEach(item=>item.status=false);
        this.setState({
            render: !this.state.render
        })
    }

render() {
        const buttonData=[
            {
                name: 'acceptButton',
                description: 'Принять',
                clickFunction: ()=>{
                    this.props.closeClick()
                    }

            },
            {
                name: 'clearButton',
                description: 'Очистить',
                clickFunction: ()=>{
                    this.clearFunction();
                }
            },
            {
                name: 'cancelButton',
                description: 'Отмена',
                clickFunction: ()=>{
                    this.clearFunction();
                    this.props.closeClick()
                }
            }
        ];

        const partsRecordArray=[];
        this.props.partsArray.forEach((item, index)=>{
                partsRecordArray[index]=<PartOfSpeech key={index} data={{
                    index: index,
                    value: item.value,
                    status: item.status,
                    onChangeFunction: this.onChangeFunction
                    }}/>
});
        return(
this.props.isOpen?
            <div className={'part-of-speech-filter'}>
                <SubmenuHeader name={'Часть речи'} closeClick={this.props.closeClick}/>
                <div className={'parts-container'}>
                    {
                       partsRecordArray
                    }
                </div>
                <div className={'filterButtonContainer'}>
                    {
                        buttonData.map(item=>
                        <FilterRuleButton key={item.name} buttonName={item.description} clickFunction={item.clickFunction}/>
                        )
                    }
                </div>
            </div>: null
        )
}

}

const mapStateToProps = state=> {
    return {
        isOpen: state.searchReducer.partsFilterIsOpen,
        partsArray: state.searchReducer.partsArray
    }
};

const mapDispatchToProps = dispatch=>{
    return {
        openFilter: ()=>dispatch()
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
    )(PartOfSpeechFilter);