import React from 'react';
import {connect} from 'react-redux';
import {getStatistics} from "../../actions/StatisticsActions";
import {Chart} from 'react-google-charts';
import './style.css';

const ChartContainer = props=>{
    const{data}=props;

    return <div className={'statistics-chart-container'}>
        <Chart
            width={data.width}
            height={data.height}
            chartType="Bar"
            loader={<div>Загрузка графика</div>}
            data={data.dataArray}
            options={{
                title: data.chartName,
                legend: {position: 'none'}
            }}/>
    </div>
};


class StatisticsPage extends React.Component{
    constructor(props){
        super(props);
        this.state={
            chartsArray:[]
        }
    }
    componentDidMount() {
        this.props.getStatistics();

    }

    componentDidUpdate(prevProps) {

        if(this.props.statistics.status === 'request_success')
            if(this.props.statistics !== prevProps.statistics
                && this.props.statistics['sex'].length>0
                && this.props.statistics['age'].length>0
                && this.props.statistics['language'].length>0
                && this.props.statistics['textTypes'].length>0
                && this.props.statistics['parts'].length>0) {
            this.createChartsArray();

        }
    }

    createArray(startArray, field, name, units){
        const array = [];
        array.push([name, units]);
        if(Array.isArray(startArray))startArray.forEach(item=>{
           array.push([item[field]+'', item['COUNT(*)']]);
        });
        return array;
    }

    createChartsArray(){
        const {statistics}=this.props;
        const charts = [
            {
                width: 250,
                height: 300,
                dataArray: this.createArray(statistics.sex, 'sex', 'Половое соотношение', 'Пол'),
                chartName: 'Половое соотношение'
            },
            {
                width: 850,
                height: 300,
                dataArray: this.createArray(statistics.age, 'age', 'Возрастное соотношение', 'Количество лет'),
                chartName: 'Возрастное соотношение'
            },
            {
                width: 750,
                height: 300,
                dataArray: this.createArray(statistics.language, 'native_language', 'Языковое соотношение', 'Язык'),
                chartName: 'Языковое соотношение'
            },
            {
                width: 350,
                height: 300,
                dataArray: this.createArray(statistics.textTypes, 'text_type', 'Тип тестового текста', 'Тип текста'),
                chartName: 'Тип тестового текста'
            },
            {
                width: 1160,
                height: 600,
                dataArray: this.createArray(statistics.parts, 'part_of_speech', 'Часть речи, в которой допущена ошибка', 'Часть речи'),
                chartName: 'Часть речи'
            }
        ];
        const chartsArray  = charts.map((item, index)=> <ChartContainer key={index} data={item}/>);
        this.setState({
            chartsArray: chartsArray
        })

    }

    render() {
        const {statistics}=this.props;
        return(
            <div className='statistics-container'>
            <StatisticHeader/>
            <CommonStatistic countOfRecords={statistics.countOfRecords} countOfChildren={statistics.countOfChildren}/>
                <div className={'statistics-charts-common-container'}>
                 {this.state.chartsArray}
                </div>
            </div>
    )
    }
}

const StatisticHeader =()=>{
    return <div className={'statistic-header-container'}>
        <h3 className={'statistic-header-text'}>Статистика</h3>
        <h3 className={'statistic-header-text-usual'}>База данных "Дети-инофоны Вологодской области"</h3>
    </div>
};

const CommonStatistic = props =>{
    return <div className={'statistic-common-container'}>

        <h2 className={'statistic-common-heading'}>Общая статистика</h2>
        <div className={'statistic-common-section'}>
            <h3 className={'statistic-common-text'}>Общее количество записей</h3>
            <h3 className={'statistic-common-text text-right'}>{props.countOfRecords}</h3>
        </div>
        <div className={'statistic-common-section'}>
            <h3 className={'statistic-common-text'}>Количество протестированных детей</h3>
            <h3 className={'statistic-common-text'}>{props.countOfChildren}</h3>
        </div>
        <h3 className={'statistic-common-text'}> </h3>
        
    </div>
};



const mapStateToProps=store=>{
    return{
            statistics: store.statisticsReducer
    }

};

const mapDispatchToProps=dispatch=>({
    getStatistics: ()=>dispatch(getStatistics())

});

export default connect(mapStateToProps,mapDispatchToProps)(StatisticsPage);