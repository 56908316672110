import React, {Fragment} from 'react';
import {ErrorCategoryFilter} from "../../../components/filters/ErrorFilter/ErrorFilter";
import PartOfSpeechFilter from "../../../components/filters/PartOfSpeechFilter/PartOfSpeechFilter";
import TextTypeFilter from "../../../components/filters/TextTypeFilter/TextTypeFilter";
import AdvancedFilter from "../../../components/filters/AdvancedFilter/AdvancedFilter";
import { connect } from 'react-redux';
import {search} from '../../../actions/SearchActions';


import icon from './submenu_icon.svg';
import './style.css'


class EditableSearch extends React.Component{
    constructor(props){
        super(props);
        this.state={
          word:''
        };
        this.searchClick=this.searchClick.bind(this);
        this.onChangeFunction=this.onChangeFunction.bind(this);
    }

    componentDidMount() {
        this.props.getDataFields();
    }

    onChangeFunction(e){
        this.setState({
            word: e.target.value
        })
}
    createArrayToSearch(array){
        const searchArray =[];
        array.forEach(item=>{
            if(item.status) searchArray.push(item.value);
        });
        return searchArray;
    }

    searchClick(){
        const {
            searchReducer,
            createRequest,
        } = this.props;
        const categoryObj={
            nonorthographicErrors:'неорфографические',
            graphicErrors: 'графические',
            orthographicErrors:'орфографические',
            grammaticalErrors:'грамматические',
            lexicalErrors: 'лексические'
        };
            let errorCategory = [];
            let errorTypes = [];
            for (let key in searchReducer.errorsObject) {
                if(searchReducer.errorsObject[key].length>0){
                    errorCategory.push(categoryObj[key]);
                    searchReducer.errorsObject[key].forEach(item=>{
                        errorTypes.push(item.toLowerCase())
                    });
                }
            }



            const dataArray = {
                wordForm: this.state.word,
                partOfSpeech: this.createArrayToSearch(searchReducer.partsArray),
                textType: this.createArrayToSearch(searchReducer.textArray),
                sex: this.createArrayToSearch(searchReducer.sexArray),
                language: this.createArrayToSearch(searchReducer.languageArray),
                errorCategory: errorCategory,
                errorType: errorTypes,
                minAge: searchReducer.minAge,
                maxAge: searchReducer.maxAge
            };


            let length = 0;

            for (let key in dataArray){
                length += dataArray[key].length
            }
            if (length>0) {
                createRequest(JSON.stringify(dataArray));
            }
            else alert('Введите словоформу или/и задайте фильтры')

    }

    render() {
        const {
            searchReducer,
            closeErrorFilterAction,
            openErrorFilterAction,
            openPartOfSpeechAction,
            closePartOfSpeechAction,
            openTextTypeAction,
            closeTextTypeAction,
            openAdvancedFilter,
            dispatchErrorTypesObject


        } = this.props;
        const searchSections=[
            {
                name: 'part',
                title: 'Часть речи',
                action: openPartOfSpeechAction

            },
            {
                name: 'type',
                title: 'Тип текста',
                action: openTextTypeAction

            },
            {
                name: 'error',
                title: 'Категория ошибки',
                action: openErrorFilterAction
            },
            {
                name: 'advanced',
                title: 'Дополнительно',
                action: openAdvancedFilter
            }
        ];
        return(
            <div className={'editable-search'}>
                    <ErrorCategoryFilter
                    isOpen={searchReducer.errorFilterIsOpen}
                    closeClick={closeErrorFilterAction}
                    acceptClick={dispatchErrorTypesObject}/>
                    <PartOfSpeechFilter
                    closeClick={closePartOfSpeechAction}/>
                    <TextTypeFilter
                    closeClick={closeTextTypeAction}/>

                    <AdvancedFilter/>

                <div className="search-form-container">
                    <WordSearch onChangeFunction={this.onChangeFunction} word={this.state.word}>
                        {
                    searchSections.map((section)=>(
                    <SearchSection key={section.title} sectionName={section.title} openFunction={section.action}/>
                        ))
                    }
                    </WordSearch>
                    <button className={"search-button"} onClick={this.searchClick}>Поиск</button>
                </div>


            </div>)
    }
}


const WordSearch= props =>{

        return(
            <Fragment>
            <h3 className={"search-section-name"}>Словоформа</h3>
            {
                props.children
            }
            <input type={"text"} className={"search-form-input"} value={props.word} onChange={props.onChangeFunction}/>
            </Fragment>)
    };



class SearchSection extends React.Component{
    constructor(props){
        super(props);
        this.openFormClick=this.openFormClick.bind(this);
    }
    openFormClick(){
        this.props.openFunction();
    }
    render() {
        return(
                <label className={"search-section-name"}
                       onClick={this.openFormClick}>
                    <img src={icon} alt={''}/>
                    {this.props.sectionName}</label>
        )
    }
}







const mapStateToProps = store =>{
    return {
        searchReducer: store.searchReducer
    }
};

const mapDispatchToProps = dispatch=>({
    closeErrorFilterAction: () => dispatch(search.closeErrorFilter()),
    openErrorFilterAction: () => dispatch(search.openErrorFilter()),
    openTextTypeAction: ()=>dispatch(search.openTextTypeFilter()),
    closeTextTypeAction: ()=>dispatch(search.closeTextTypeFilter()),
    openPartOfSpeechAction: ()=>dispatch(search.openPartOfSpeechFilter()),
    closePartOfSpeechAction: ()=>dispatch(search.closePartOfSpeechFilter()),
    openAdvancedFilter: ()=>dispatch(search.openAdvancedFilter()),
    dispatchErrorTypesObject: errorTypesObject=>dispatch(search.addErrorTypes(errorTypesObject)),
    getDataFields: ()=>dispatch(search.getDataFields()),
    createRequest: dataArray=>dispatch(search.createRequest(dataArray))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditableSearch)