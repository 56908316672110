import {
    USER_NUMBER_REQUEST,
    USER_NUMBER_RECEIVED,
    USER_DATA_RECEIVED,
    LOGIN_REQUEST,
    REGISTRATION_REQUEST,
    USER_RELOAD
} from "../actions/UserAction";


export const initialState = {
    username: 'guest',
    password: 'guest',
    idAccessLevel: 4,
    accessLevel: 'guest',
    surname: 'guest',
    name: 'guest',
    email: 'mail',
    status: 'first',
    userNum: undefined,
    isFetching: false,
};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_NUMBER_REQUEST:
            return{
                ...state,
                status: action.payload.status,
                isFetching: action.payload.isFetching,
            };
        case USER_NUMBER_RECEIVED:
            return {
              ...state,
                userNum: action.payload.userNum,
                status: action.payload.status,
                isFetching: action.payload.isFetching
            };
        case LOGIN_REQUEST:
            return {
                ...state,
                status: action.payload.status,
                isFetching: true
            };

        case  REGISTRATION_REQUEST:
            return {
                ...state,
                status: action.payload.status,
                isFetching: true
            };

        case USER_DATA_RECEIVED:
                return {
                  ...state,
                    username: action.payload.username,
                    password: action.payload.password,
                    idAccessLevel: action.payload.idAccessLevel,
                    accessLevel: action.payload.accessLevel,
                    surname: action.payload.surname,
                    name: action.payload.name,
                    email: action.payload.email,
                    status: action.payload.status,
                    userNum: action.payload.userNum,
                    isFetching: action.payload.isFetching,
                };
        case USER_RELOAD:{
            return {
                ...state,
                username: action.payload.userData.username,
                password: action.payload.userData.password,
                idAccessLevel: action.payload.userData.id_access_level,
                accessLevel: action.payload.userData.accessLevel,
                surname: action.payload.userData.surname,
                name: action.payload.userData.name,
                email: action.payload.userData.email,
                status: action.payload.userData.status,
                userNum: action.payload.userData.userNum,
            }
        }

        default:
            return state

    }


}