import React from 'react';
import EditableSearch from './EditableSearch/EditableSearch'
import ResultsContainer from './ResultsContainer/ResultsContainer';

import './style.css';



class SearchPage extends React.Component{
    render() {
        return(
            <div className={"search-container"}>
            <div className="editable-search-container">
                <h4 className={"search-name"}>Лексико-грамматический поиск</h4>
                <EditableSearch/>
            </div>
                <ResultsContainer/>
            </div>
        )
    }
}





export default SearchPage;








