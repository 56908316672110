import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import './style.css';
import {ResultRecord} from '../ResultRecord/ResultRecord';

class ResultsContainer extends React.Component
{
    constructor(props){
        super(props);
        this.state={
            active: 0
        };
        this.changePageClick=this.changePageClick.bind(this);
    }
    changePageClick(number){
        this.setState({
            active: number-1
        })
    }

    render(){
      const {
          searchReducer
      }=this.props;
        let results=[];
        if(searchReducer.dataFromDB) results = searchReducer.dataFromDB;
        let content;
        if(Object.keys(results).length ===0 && searchReducer.status ==='searchDataReceived'){
            content=<h3 className={'results-placeholder'}>По данному запросу ничего не найдено</h3>
        }
        else if(Object.keys(results).length ===0) {
            content=<h3 className={'results-placeholder'}>Здесь будут отображены результаты поиска</h3>
        }
        else {
            results= Array.from(results);
            results.unshift({
                text_type: 'тип текста',
                part_of_speech: 'часть речи',
                error_category: 'категория ошибки',
                error_type: 'тип ошибки',
                name: 'имя ребенка',
                age: 'возраст',
                sex: 'пол',
                native_language: 'родной язык',
                year: 'год сбора материалов',
                word: 'слово с ошибкой',
                context1: 'словосочетание, содержащее слово с ошибкой',
                context2: 'словочетание с исправленной ошибкой',
                correct: 'исправленной ошибкой'
            });
            content = results.map((item, index)=><ResultRecord key={index} index={index} data={item}/>
            )
        }
        let viewArray=[];
        if(Array.isArray(content)){
        for(let i=(this.state.active)*10; i<(this.state.active)*10+10; i++){
            if(content[i]) viewArray.push(content[i])
        }}
        return(
            <div className={"results"}>
                {
                   viewArray.length===0 ? content :
                            <Fragment>
                                <PagesNumberButtons active={this.state.active}
                                                    countOfPages={Math.ceil(results.length/10)}
                                                    changePageClick={this.changePageClick}/>
                                <div className={"results-container"}>
                                {viewArray}
                                </div>
                            </Fragment>
                }


            </div>
        )
    }
}

const PagesNumberButtons=props=>{
        let count =0;
        if(props.countOfPages) count = props.countOfPages;
        let pageNumbersArray=[];
        for( let i = 0; i <count; i++){
            if(props.active === i) pageNumbersArray[i] =
                <PageNumberButton key={i} activePage={true} changePageClick={props.changePageClick} number={i+1}/>;
            else pageNumbersArray[i] =
                <PageNumberButton key={i} activePage={false} changePageClick={props.changePageClick} number={i+1}/>;
        }
        return(
            <div className={'page-number-buttons-container'}>
                {pageNumbersArray}
            </div>)
};

const PageNumberButton =props => {
    return(
    <button onClick={()=>props.changePageClick(props.number)}
            className={props.activePage? 'page-number-button-active': 'page-number-button'}>
        {props.number}</button>
    )};

const mapStateToProps = store =>{
    return {
        searchReducer: store.searchReducer
    }
};

export default connect(
    mapStateToProps
)(ResultsContainer)