import React from 'react';
import './style.css';

const BreadCrumbs = (props) =>{
    return(
        <div className="bread-crumbs-container">
            {props.children}
        </div>
    )
};
export {BreadCrumbs};