import React from 'react';
import {connect} from 'react-redux';
import {FilterRuleButton, SubmenuHeader} from "../CommonParts/CommonParts";
import './style.css';

const TextType = props=>{
    const {data}=props;
    return <label className={'text-label'}>
        <input className={'textCheckbox'}
               value={data.value}
               type={'checkbox'}
               checked={data.status}
               name={'textType'}
               onChange={data.onChangeFunction(data.index)}/>
        {data.value}
    </label>
};

class TextTypeFilter extends React.Component{
    constructor(props){
        super(props);
        this.state={
            render: false
        };
        this.onChangeFunction=this.onChangeFunction.bind(this);

    }
    onChangeFunction(index) {
        return e=>{
            this.props.textArray[index].status = !!e.target.checked;
            this.setState({
                render: !this.state.render
            })
        }

    }


    clearFunction(){
        this.props.textArray.forEach(item=>item.status=false);
        this.setState({
            render: !this.state.render
        })
    }




    render() {
        const buttonData=[
            {
                name: 'acceptButton',
                description: 'Принять',
                clickFunction: ()=>{

                    this.props.closeClick()
                }

            },
            {
                name: 'clearButton',
                description: 'Очистить',
                clickFunction: ()=>{
                    this.clearFunction();
                }
            },
            {
                name: 'cancelButton',
                description: 'Отмена',
                clickFunction: ()=>{
                    this.clearFunction();
                    this.props.closeClick()
                }
            }
        ];

        return(
this.props.isOpen?
            <div className={'text-type-filter'}>
                <SubmenuHeader name={'Тип текста'} closeClick={this.props.closeClick}/>
                <div className={'parts-container'}>
                    {
                        this.props.textArray.map((item,index)=><TextType
                            key={index}
                            data={{
                                index: index,
                                value: item.value,
                                status: item.status,
                                onChangeFunction: this.onChangeFunction
                            }}
                        />)
                    }
                </div>
                <div className={'filterButtonContainer'}>
                    {
                        buttonData.map(item=>
                            <FilterRuleButton key={item.name} buttonName={item.description} clickFunction={item.clickFunction}/>
                        )
                    }
                </div>
            </div>:null
        )
    }

}


const mapStateToProps=state=>{
    return {
        isOpen: state.searchReducer.textFilterIsOpen,
        textArray: state.searchReducer.textArray
    }
};


export default connect(mapStateToProps)(TextTypeFilter)