import React from 'react';
import { connect } from 'react-redux';
import {
    getUserNum,
    userReload
} from "../../actions/UserAction";
import {NavLink} from "react-router-dom";
import './style.css';


class User extends React.Component{
    constructor(props){
    super(props);
    this.state={isOpen:false}
    this.focusRef = React.createRef();
}
componentDidMount() {
    if (sessionStorage.getItem("userData")!==null
        && JSON.parse(sessionStorage.getItem("userData")).idAccessLevel!==4)
    {
        const user=JSON.parse(sessionStorage.getItem('userData'));
        this.props.userReload(user);
    }
     else   this.props.getUserNumber();
     if(this.state.isOpen) this.focusRef.current.addEventListener('click', this.onClickOutsideHandler)
}

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    openClick =()=>{
        this.setState({isOpen: true})
    }

    onClickHandler=()=> {
        this.setState(currentState => ({
            isOpen: !currentState.isOpen
        }));
    }

    onClickOutsideHandler=(event)=> {
        if (this.state.isOpen && !this.toggleContainer.current.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }

    render() {
        const {userData}=this.props;

        return userData.userName !== 'guest'?
            <div className={'user-container'}>
            <div onClick={this.openClick} className={'user'}>
                <h3 className={'username'}>{userData.surname+' '+userData.name[0]+'.'}</h3>
                <h4 className={'user-access'}>{userData.accessLevel}</h4>
            </div>
                {this.state.isOpen &&
                (userData.accessLevel==='администратор' || userData.accessLevel === 'модератор') ?
                    <div className={'user-container-entrance'} ref={this.focusRef}
                         onClick={this.onClickHandler}
                         tabIndex={-1}>
                   <nav>
                        <NavLink className={'link-to-admin-panel'} to={'/administration/editing'}>
                            Панель администратора
                        </NavLink>
                   </nav>

                </div>:null}
            </div>:null

}
}

const mapStateToProps=store=>{
    return {
        userData: store.userReducer
    }
};
const mapDispatchToProps=dispatch=>(
    {
        getUserNumber: ()=>dispatch(getUserNum()),
        userReload: userData=>dispatch(userReload(userData))
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(User)