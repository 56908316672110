import React, {Fragment} from 'react';
import search from '../../../img/search.png';
import searchResults from'../../../img/searchResults.png';
import filterPartOfSpeech from '../../../img/filterPartOfSpeech.png';
import filterErrorCategory from '../../../img/filterErrorCategory.png';
import statistics from '../../../img/statistics.png';
import regButton from '../../../img/regButton.png';
import enterForm from '../../../img/enterForm.png';
import regForm from  '../../../img/registrationForm.png';
import './style.css'

const FaqHeadingBlock=()=>{
    return <div className={'faq-heading-block'}><h3 className={'faq-heading-text'}>FAQ</h3></div>
};

const FaqContainer=props=>{
    return <div className={'faq-container'}>
        {props.children}
    </div>
};

class FaqSubmenuHeader extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isOpen:false
        };
        this.onClick=this.onClick.bind(this);
    }
    onClick(){
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    render() {
        const isOpen=this.state.isOpen;
        return <Fragment>
        <div className={isOpen?'faq-submenu-header-container faq-submenu-header-open':'faq-submenu-header-container faq-submenu-header-close'}>
            <h3 className={'faq-submenu-question'}>{this.props.question}</h3>
            <button className={'faq-submenu-button'} onClick={this.onClick}>{isOpen? '-':'+'}</button>
        </div>
            {isOpen ? this.props.children: null}
        </Fragment>
    }
}

class FaqSubmenuText extends React.Component{
    constructor(props){
        super(props);
        this.state={
            height: 0
        }
    }


    render() {
    const answer=this.props.answer;
    return <div className={'faq-submenu-text-container'}>
        {answer}
    </div>
}


}

const helpTextsArray=[
    {
        id:1,
        question: 'Как найти ошибку',
        answer: <Fragment><h3 className={'faq-submenu-text'}>
            В на вкладке "Поиск" в строке "Лексико-грамматический поиск" введите нужную словоформу и нажмите кнопку "Поиск":
        </h3>
        <img src={search} alt={'Поиск'}/>
            <h3 className={'faq-submenu-text'}>
                В блоке вывода результатов вы получите найденные записи в базе данных.
            </h3>
            <img src={searchResults} alt={'Результаты поиска'}/>
        </Fragment>
    },
    {
        id:2,
        question: 'Что такое лексико-грамматический поиск?',
        answer:  <Fragment><h3 className={'faq-submenu-text'}>
            Лексико-грамматический поиск позволяет искать все вхождения лексемы, вхождения лексемы в указанной части речи, а также позволяет указать тип ошибки.
        </h3>
            <img src={search} alt={'Поиск'}/>
            <h3 className={'faq-submenu-text'}>
                Для выбора части речи, в которой предположительно сделана ошибка, нажмите кнопку "Часть речи", в открывшемся окне выберите необходимые части речи и нажмите "Принять"
            </h3>
            <img src={filterPartOfSpeech} alt={'Фильтр "Часть речи"'}/>
            <h3 className={'faq-submenu-text'}>
                Для выбора категории и типа ошибки нажмите кнопку "Категория ошибки", откроется фильтр категории и типов ошибок
            </h3>
            <img src={filterErrorCategory} alt={'Фильтр "Часть речи"'}/>
            <h3 className={'faq-submenu-text'}>
                Для выбора типа ошибки поставьте галочку напротив требуемого значения, для выбора всех ошибок определенной категории поставьте галочку в заголовочной части категории ошибок, после выбора всех необходимых ошибок нажмите кнопку "Принять"
            </h3>
        </Fragment>
    },
    {
        id:3,
        question: 'Как получить общую статиcтику по хранимым записям в базе данных',
        answer:<Fragment><h3 className={'faq-submenu-text'}>
            Для получения статистики по записям, хранящимся в базе данных перейдите на вкладку "Статистика". После перехода загрузятся общая статистика базы данных
        </h3>
            <img src={statistics} alt={'Поиск'}/>

        </Fragment>
    },
    {
        id:4,
        question: 'Как зарегистрироваться',
        answer:<Fragment><h3 className={'faq-submenu-text'}>
            Для регистрации нажмите кнопку "Вход/регистрация" в верхнем правом углу экрана
        </h3>
            <img src={regButton} alt={'Кнопка регистрации'}/>
            <h3 className={'faq-submenu-text'}>
                В открывшейся форме нажмите на ссылку "Регистрация"
            </h3>
            <img src={enterForm} alt={'Форма входа'}/>
            <h3 className={'faq-submenu-text'}>
                Заполните обязательные поля в форме регистрации и нажмите на кнопку "Регистрация"
            </h3>
            <img src={regForm} alt={'Форма регистрации'}/>
            <h3 className={'faq-submenu-text'}>
                Если все данные введены правильно произойдет успешная регистрация
            </h3>
        </Fragment>
    },
    {
        id:5,
        question: 'Какие преимущества дает регистрация',
        answer:<Fragment><h3 className={'faq-submenu-text'}>
            Зарегистрированный пользователь получает возможность стать модератором. Модераторы имеют доступ к приложению для управления информацией, хранящейся в базе данных.
            Пользователь может получить права модератора двумя способами:</h3>
            <ul className={'help-adv-user-ul'}>
                <li>отправить запрос администратору, после подверждения которого, пользователь становится модератором;</li>
                <li>получить прямое назначение от администратора;</li>
            </ul>

        </Fragment>
    }
];

const HelpPage = () =>{
  return(
      <div className="help-container">
          <FaqHeadingBlock/>
          <FaqContainer>
              {
              helpTextsArray.map(item=>
                  <FaqSubmenuHeader key={item.id} question={item.question}>{
                      <FaqSubmenuText id={item.id} answer={item.answer} num={item.id}/>
                  }
                  </FaqSubmenuHeader>)
              }
          </FaqContainer>
      </div>
  )
};
export {HelpPage}